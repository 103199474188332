import React from 'react'
import { Flex, H6, Caption1 } from '@components/atoms'
import { Link } from 'react-router-dom'
import { STORAGE_URL } from '@consts/'
import styled from 'styled-components'
import { colors } from '@colors'
import { handleTrackImgError } from '@utils/handler'
import { default_music_img } from '@images/'
import Image from './Image'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const SearchTrackItem = ({ item, setIsSearch }) => {
  return (
    <Flex style={{ width: '100%', padding: '10px 0' }}>
      <Link
        to={
          `/track/${item.customUrlInfo
          && item.customUrlInfo.autoUrl
          && item.customUrlInfo.autoUrl}`
        }
        style={{ width: '100%' }}
        onClick={() => setIsSearch(false)}
      >
        <Flex style={{ width: '100%' }}>
          <Image
            src={
              item && item.albumInfo && item.albumInfo.image64Path
                ? STORAGE_URL + item.albumInfo.image64Path
                : default_music_img
            }
            size={['36px', '36px']}
            style={
              {
                marginRight: '10px',
                borderRadius: '2px',
                border: `0.5px solid ${colors.inActive}`,
              }
            }
            onError={e => handleTrackImgError(e)}
          />
          <Flex type="column" style={{ flex: 1, justifyContent: 'center' }}>
            <Flex style={{ marginBottom: '2px' }}>
              <H6 type={'Regular'} align="left" style={ellipsisStyle}>
                {item.title}
              </H6>
            </Flex>
            <Flex>
              <Caption1
                type={'Regular'}
                align="left"
                color="#949494"
                style={ellipsisStyle}
              >
                {
                  item.releaseArtistList
                  && item.releaseArtistList.map(
                    (a, index) =>
                      `${a.name}
                    ${
    !(index === item.releaseArtistList.length - 1)
      ? ',\u00A0'
      : ''
    }`,
                  )
                }
              </Caption1>
            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}

export default SearchTrackItem
