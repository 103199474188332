import React, { useEffect, useRef, useState } from 'react'

import { Helmet } from 'react-helmet'
import { RewardShareTemplate } from '@components/templates'
import { download_preview_img } from '@images/index'
import { inject, observer } from 'mobx-react'
import NotificationTemplate from '@components/templates/NotificationTemplate'

const NotificationPage = ({ match, authStore, history }) => {
  const currentUserId = authStore?.currentUser?._id
  const fetchNotification = authStore.fetchNotificationList || (() => {})
  const notificationList = authStore.notificationList || (() => {})

  const fetchNotifications = async () => {
    const result = await fetchNotification({
      __offset: 0,
      __limit: 20,
      type:
        authStore?.currentUser?.type === 'artist'
        || authStore?.currentUser?.type === 'company'
          ? 'NEW_ALBUM,MELON_LISTENER_NEW_TRACK,MELON_LISTENER_INCREASE_TRACK,MELON_TOP_100_TRACK'
          : 'NEW_ALBUM,MELON_TOP_100_TRACK',
    })

    if (!result) return
  }

  useEffect(() => {
    if (currentUserId) {
      fetchNotifications()
    }
  }, [currentUserId])

  return (
    <>
      {
        <NotificationTemplate
          notificationList={notificationList}
          fetchNotifications={fetchNotification}
          history={history}
        />
      }
    </>
  )
}

export default inject('authStore')(observer(NotificationPage))
