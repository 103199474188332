import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

import { move_btn_img, move_prev_btn_img } from '@images'
import { Flex, Caption1, H5 } from '@components/atoms'
import { SearchTrackItem } from '@components/molecules'

/*
 * ./SearchArtistList 로직 동일
 */
const SearchTrackList = ({
  showDetail,
  setIsSearch,
  searchTrackList,
  keyword,
  firstFetchSearchDetail,
  moreFetchSearchDetail,
}) => {
  const [offset, setOffset] = useState(0)
  const [isHasMore, setIsHasMore] = useState(true)

  const countOffset = () => {
    setOffset(offset + 1)
  }

  const onLoadMore = async () => {
    if (offset < 1) {
      const res = await firstFetchSearchDetail('track', keyword)
      setIsHasMore(res)
    }
    else {
      const res = await moreFetchSearchDetail('track', keyword, offset)
      setIsHasMore(res)
    }
  }

  useEffect(() => {
    onLoadMore()
  }, [offset])

  return (
    <Flex type="column" style={{ padding: '0px 20px' }}>
      <Flex style={{ alignItems: 'center', height: '36px' }}>
        <H5 type="Bold" align="left">
          트랙
        </H5>
      </Flex>
      {
        searchTrackList && searchTrackList.length >= 1 ? (
          <InfiniteScroll
            dataLength={searchTrackList.length}
            scrollThreshold={0.9}
            next={countOffset}
            hasMore={isHasMore}
            loader={
              <ReactLoading
                type="spin"
                color="black"
                style={{ width: '40px', height: '40px', margin: '0 auto' }}
              />
            }
            style={{ overflow: 'unset' }}
          >
            {
              searchTrackList.map((item, index) => {
                return (
                  <SearchTrackItem
                    item={item}
                    key={index}
                    setIsSearch={setIsSearch}
                  />
                )
              })
            }
          </InfiniteScroll>
        ) : (
          <Caption1 align="left">검색 결과가 없습니다.</Caption1>
        )
      }
    </Flex>
  )
}

export default SearchTrackList
