import React from 'react'
import { Flex, H6, Caption1 } from '@components/atoms'
import { Link } from 'react-router-dom'
import { STORAGE_URL } from '@consts/'
import styled from 'styled-components'
import { colors } from '@colors/'
import { default_profile_img } from '@images'
import { handleArtistImgError } from '@utils/handler'
import Image from './Image'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const SearchArtistItem = ({ item, setIsSearch }) => {
  return (
    <Flex style={{ width: '100%' }}>
      <Link
        to={`/artist/${item.uniqueName}`}
        style={{ width: '100%' }}
        onClick={() => setIsSearch(false)}
      >
        <Flex style={{ width: '100%', padding: '10px 0px' }} align={'center'}>
          <Image
            src={
              item && item.artistInfo && item.artistInfo.image64Path
                ? STORAGE_URL + item.artistInfo.image64Path
                : default_profile_img
            }
            size={['36px', '36px']}
            style={
              {
                marginRight: '10px',
                borderRadius: '28px',
                border: `solid 0.5px ${colors.inActive}`,
              }
            }
            onError={e => handleArtistImgError(e)}
          />
          <Flex type="column" style={{ flex: 1, justifyContent: 'center' }}>
            <H6 type={'regular'} align="left" style={ellipsisStyle}>
              {item.name}
            </H6>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}

export default SearchArtistItem
