import React from 'react'
import { BodyWrapper, Caption1, Caption2, Flex, Input } from '@components/atoms'
import { event_description, event_search } from '@images/'
import { STORAGE_URL } from '@consts'
import styled from 'styled-components'
import { default_profile_img, search_outline_24px } from '@images/index'
import { Image } from '@components/molecules'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { handleArtistImgError } from '@utils/handler'
import { colors } from '@colors'

const FanSpotRequestEventTemplate = ({ setIsFanSpotModalOpen, history }) => {
  const testData = [
    {
      artistId: '5edef51da070850007fa5e5c',
      uniqueName: 'bts',
      artistLink: 'https://plam.in/artist/bts',
      fanSpotLink: 'https://plam.in/fanspot/bts',
      name: '방탄소년단',
      image128Path: '/upload/image/128/1686287148529-8lio3tv5d.jpg',
      image512Path: '/upload/image/512/1686287148529-8lio3tv5d.jpg',
      image64Path: '/upload/image/64/1686287148529-8lio3tv5d.jpg',
      imageOriginalPath: '/upload/image/original/1686287148529-8lio3tv5d.jpg',
    },
    {
      artistId: '5ef2016d494b7f751f7a79bd',
      uniqueName: 'seventeen',
      artistLink: 'https://plam.in/artist/seventeen',
      fanSpotLink: 'https://plam.in/fanspot/seventeen',
      name: '세븐틴',
      image128Path: '/upload/image/128/1728954278957-8m29qqjou.jpg',
      image512Path: '/upload/image/512/1728954278957-8m29qqjou.jpg',
      image64Path: '/upload/image/64/1728954278957-8m29qqjou.jpg',
      imageOriginalPath: '/upload/image/original/1728954278957-8m29qqjou.jpg',
    },
    {
      artistId: '64ae0432cb4c6000087da84e',
      uniqueName: 'zerobaseone',
      artistLink: 'https://plam.in/artist/zerobaseone',
      fanSpotLink: 'https://plam.in/fanspot/zerobaseone',
      name: '제로베이스원',
      image128Path: '/upload/image/128/1724810636707-8m0d7pwv7.jpg',
      image512Path: '/upload/image/512/1724810636707-8m0d7pwv7.jpg',
      image64Path: '/upload/image/64/1724810636707-8m0d7pwv7.jpg',
      imageOriginalPath: '/upload/image/original/1724810636707-8m0d7pwv7.jpg',
    },
    {
      artistId: '64e568760a0f5d0008d84c92',
      uniqueName: 'riize',
      artistLink: 'https://plam.in/artist/riize',
      fanSpotLink: 'https://plam.in/fanspot/riize',
      name: '라이즈',
      image128Path: '/upload/image/128/1718755036865-8lxl2de4h.jpg',
      image512Path: '/upload/image/512/1718755036865-8lxl2de4h.jpg',
      image64Path: '/upload/image/64/1718755036865-8lxl2de4h.jpg',
      imageOriginalPath: '/upload/image/original/1718755036865-8lxl2de4h.jpg',
    },
    {
      artistId: '6480535e0a17f200089e2ed1',
      uniqueName: 'boynextdoor',
      artistLink: 'https://plam.in/artist/boynextdoor',
      fanSpotLink: 'https://plam.in/fanspot/boynextdoor',
      name: 'BOYNEXTDOOR',
      image128Path: '/upload/image/128/1726821119123-8m1agpj10.jpg',
      image512Path: '/upload/image/512/1726821119123-8m1agpj10.jpg',
      image64Path: '/upload/image/64/1726821119123-8m1agpj10.jpg',
      imageOriginalPath: '/upload/image/original/1726821119123-8m1agpj10.jpg',
    },
    {
      artistId: '646ec03f50cbd0000812b0c3',
      uniqueName: 'plave',
      artistLink: 'https://plam.in/artist/plave',
      fanSpotLink: 'https://plam.in/fanspot/plave',
      name: '플레이브',
      image128Path: '/upload/image/128/1731579393715-8m3h5nv78.jpg',
      image512Path: '/upload/image/512/1731579393715-8m3h5nv78.jpg',
      image64Path: '/upload/image/64/1731579393715-8m3h5nv78.jpg',
      imageOriginalPath: '/upload/image/original/1731579393715-8m3h5nv78.jpg',
    },
    {
      artistId: '5ef2091a494b7f751f7a7c70',
      uniqueName: 'blackpink',
      artistLink: 'https://plam.in/artist/blackpink',
      fanSpotLink: 'https://plam.in/fanspot/blackpink',
      name: '블랭핑크',
      image128Path: '/upload/image/128/1672379751548-8lca3q2yk.jpg',
      image512Path: '/upload/image/512/1672379751548-8lca3q2yk.jpg',
      image64Path: '/upload/image/64/1672379751548-8lca3q2yk.jpg',
      imageOriginalPath: '/upload/image/original/1672379751548-8lca3q2yk.jpg',
    },
    {
      artistId: '5fed8b4e3407fa5319cebdb4',
      uniqueName: 'aespa',
      artistLink: 'https://plam.in/artist/aespa',
      fanSpotLink: 'https://plam.in/fanspot/aespa',
      name: '에스파',
      image128Path: '/upload/image/128/1729668281025-8m2lju37m.jpg',
      image512Path: '/upload/image/512/1729668281025-8m2lju37m.jpg',
      image64Path: '/upload/image/64/1729668281025-8m2lju37m.jpg',
      imageOriginalPath: '/upload/image/original/1729668281025-8m2lju37m.jpg',
    },
    {
      artistId: '61a8661a33844804a8d59549',
      uniqueName: 'ive',
      artistLink: 'https://plam.in/artist/ive',
      fanSpotLink: 'https://plam.in/fanspot/ive',
      name: '아이브',
      image128Path: '/upload/image/128/1714633131480-8lvowankp.jpg',
      image512Path: '/upload/image/512/1714633131480-8lvowankp.jpg',
      image64Path: '/upload/image/64/1714633131480-8lvowankp.jpg',
      imageOriginalPath: '/upload/image/original/1714633131480-8lvowankp.jpg',
    },
    {
      artistId: '633d1611b424517488e7a1e1',
      uniqueName: 'newjeans',
      artistLink: 'https://plam.in/artist/newjeans',
      fanSpotLink: 'https://plam.in/fanspot/newjeans',
      name: '뉴진스',
      image128Path: '/upload/image/128/1718942517232-9lxo5zqwh.jpg',
      image512Path: '/upload/image/512/1718942517232-9lxo5zqwh.jpg',
      image64Path: '/upload/image/64/1718942517232-9lxo5zqwh.jpg',
      imageOriginalPath: '/upload/image/original/1718942517232-9lxo5zqwh.jpg',
    },
    {
      artistId: '6346887a0e90a77056b0ae52',
      uniqueName: 'lesserafim',
      artistLink: 'https://plam.in/artist/lesserafim',
      fanSpotLink: 'https://plam.in/fanspot/lesserafim',
      name: '르세라핌',
      image128Path: '/upload/image/128/1725253080629-8m0kj50s5.jpg',
      image512Path: '/upload/image/512/1725253080629-8m0kj50s5.jpg',
      image64Path: '/upload/image/64/1725253080629-8m0kj50s5.jpg',
      imageOriginalPath: '/upload/image/original/1725253080629-8m0kj50s5.jpg',
    },
    {
      artistId: '66039dd6ce79a90008f93151',
      uniqueName: 'illit',
      artistLink: 'https://plam.in/artist/illit',
      fanSpotLink: 'https://plam.in/fanspot/illit',
      name: '아일릿',
      image128Path: '/upload/image/128/1729669905735-8m2lkswuf.jpg',
      image512Path: '/upload/image/512/1729669905735-8m2lkswuf.jpg',
      image64Path: '/upload/image/64/1729669905735-8m2lkswuf.jpg',
      imageOriginalPath: '/upload/image/original/1729669905735-8m2lkswuf.jpg',
    },
  ]

  return (
    <BodyWrapper style={{ padding: 0, minHeight: '100vh' }}>
      <Flex type="column">
        <TitleImage src={event_description} />
        <Flex type={'column'} style={{ width: '100%', background: '#161616' }}>
          <SearchImage src={event_search} />

          <SearchInplamInputContainer
            align={'center'}
            style={{ paddingRight: '12px' }}
            onClick={
              () => {
                setIsFanSpotModalOpen(true)
              }
            }
          >
            <SearchInplamInput
              placeholder="아티스트 검색"
              value={''}
              disabled
              style={{ pointerEvents: 'none' }}
            />
            <Image src={search_outline_24px} size={['20px', '20px']} />
          </SearchInplamInputContainer>
          <Flex
            type={'row'}
            style={
              {
                padding: '42px 46px',
                flexWrap: 'wrap',
                columnGap: '16px',
                rowGap: '30px',
              }
            }
            justify={'space-between'}
          >
            {
testData?.map(val => {
  return <FanSpotItem item={val} />
})
            }
          </Flex>
        </Flex>
      </Flex>
    </BodyWrapper>
  )
}

export default FanSpotRequestEventTemplate

const TitleImage = styled.img`
  width: '100%';
  height: 'auto';
`
const SearchImage = styled.img`
  width: 51.6vw; /* 186px */
  max-width: 216px;
  margin: 32px 0 27px 0;
  align-self: center;
  height: auto;
`

const RixText = styled.p`
  /* font-family: NotoSansCJKkr-Bold; */
  font-family: RixYeoljeongdoPR;
  font-size: ${({ size }) => (size ? `${size}px` : '14px')};
  color: ${({ color }) => (color ? `${color}` : '#242424')};
  text-align: ${({ align }) => (align ? `${align}` : 'center')};
  margin: 0;
  padding: 0;
`

const LogoImg = styled.img`
  width: 58px;
  height: 16px;
`

const SearchInplamInputContainer = styled(Flex)`
  /* width: 100%; */
  height: 40px;

  border: none !important;
  background-color: #f4f4f4;
  border-radius: 4px;

  margin: 0px 20px;

  box-sizing: border-box;
`
const SearchInplamInput = styled(Input)`
  flex: 1;
  font-size: 12px;
  border: none !important;
  background-color: transparent;
  padding: 11.5px 8px 11.5px 12px;
`
const Name = styled(Caption1)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #fff;
`

const FanSpotItem = ({ item }) => {
  return (
    <Flex style={{}}>
      <Link to={`/fanspot/${item?.uniqueName}`}>
        <Flex
          type="column"
          align="center"
          style={
            {
              gap: 5,
              width: 64,
              position: 'relative',
              paddingBottom: '4px',
            }
          }
        >
          <Flex style={{ position: 'relative' }}>
            <Image
              src={
                item && item.image64Path
                  ? STORAGE_URL + item.image64Path
                  : default_profile_img
              }
              size={['64px', '64px']}
              style={
                {
                  borderRadius: '32px',
                  boxSizing: 'border-box',
                }
              }
              onError={e => handleArtistImgError(e)}
            />
          </Flex>

          <Name type={'Regular'}>{item?.name || item?.uniqueName}</Name>
        </Flex>
      </Link>
    </Flex>
  )
}
