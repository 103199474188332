import React, { useEffect, useRef, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import './FanspotModal.css'
import { useStore } from '@utils/hooks'
import { Observer } from 'mobx-react-lite'
import { Caption1, Flex, H6, Input } from '@components/atoms'
import styled from 'styled-components'
import { Image } from '@components/molecules'

import {
  default_profile_img,
  star_fill_24,
  search_outline_24px,
} from '@images/'
import { STORAGE_URL } from '@consts'

const FanspotRequestListBottomModal = ({
  isModalOpen = false,
  setIsFanSpotModalOpen = () => {},
  history,
}) => {
  const { inplamStore } = useStore()

  const fetchTotalInplamList = inplamStore.fetchTotalInplamList || (() => {})

  const scrollerRef = useRef(null)

  const [fanSpotList, setFanSpotList] = useState([])
  const [isFavorite, setIsFavorite] = useState(1)

  const [totalInplamoffset, setTotalInplamOffset] = useState(0)
  const [inputValue, setinputValue] = useState('')
  const [searchName, setSearchName] = useState('')
  const [isFinish, setIsFinish] = useState(false)

  const [isFirst, setIsFirst] = useState(true)

  const handleBottomModalOpen = () => {
    setIsFanSpotModalOpen(true)
  }
  const handleBottomModalClose = () => {
    setFanSpotList([])
    setinputValue('')
    setIsFanSpotModalOpen(false)
  }
  const debouncedOnChange = useRef(
    _.debounce(value => {
      setSearchName(value)
      if (totalInplamoffset > 0) {
        setTotalInplamOffset(0)
      }
    }, 300),
  ).current

  const handleSearch = value => {
    setinputValue(value)
    debouncedOnChange(value)
  }

  const fetchTotalList = async ({
    __limit = 20,
    __isFavorite = 1,
    __offset = 0,
    __name,
  }) => {
    const options = {
      offset: __offset,
      limit: __limit,
      isFavorite: __isFavorite,
      noNeedStore: true,
    }

    if (__name) {
      options.name = __name
    }

    const result = await fetchTotalInplamList(options)

    if (__isFavorite === 0 || __offset > 0) {
      setFanSpotList(val => [...val, ...result])
    }
    else {
      setFanSpotList(result)
    }

    if (__isFavorite === 1 && result?.length < 20) {
      setIsFavorite(0)

      const options2 = {
        offset: 0,
        limit: 20,
        isFavorite: 0,
        noNeedStore: true,
      }

      if (__name) {
        options2.name = __name
      }

      const result2 = await fetchTotalInplamList(options2)

      setFanSpotList(val => [...val, ...result2])

      setTotalInplamOffset(1)

      if (result?.length + result2?.length < 20) {
        setIsFinish(true)
      }
    }
    else if (result?.length < 20) {
      setIsFinish(true)
    }
  }

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false)
      return
    }
    if (searchName) {
      fetchTotalList({
        __offset: 0,
        __name: searchName,
        __isFavorite: 1,
      })
    }
    else {
      fetchTotalList({
        __offset: 0,
        __isFavorite: 1,
      })
    }
    setIsFavorite(1)
  }, [searchName])

  useEffect(() => {
    const handleScroll = async () => {
      if (
        scrollerRef.current
        && scrollerRef.current.scrollTop + scrollerRef.current.clientHeight
          >= scrollerRef.current.scrollHeight
        && !isFinish
      ) {
        const options = {
          __offset: totalInplamoffset + 1,
          __isFavorite: isFavorite,
        }

        if (searchName) {
          options.__name = searchName
        }
        await fetchTotalList(options)
      }
    }

    const scroller = scrollerRef.current
    if (scroller) {
      scroller.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scroller) {
        scroller.removeEventListener('scroll', handleScroll)
      }
    }
  }, [fetchTotalList])

  return (
    <BottomSheet
      style={{ maxWidth: '480px' }}
      open={isModalOpen}
      blocking
      onDismiss={
        () => {
          handleBottomModalClose()
        }
      }
      snapPoints={
        ({ minHeight }) => {
          return minHeight
        }
      }
      onSpringStart={
        async event => {
          if (event.type === 'OPEN') {
          // the bottom sheet gently waits
          // and now we can proceed
          // fetchTotalList({ __offset: 0 })
          }
        }
      }
    >
      <Flex type={'column'} style={{ padding: '8px 20px' }}>
        <SearchInplamInputContainer
          align={'center'}
          style={{ paddingRight: '12px' }}
        >
          <SearchInplamInput
            placeholder="팬스팟 검색"
            onChange={e => handleSearch(e.target.value)}
            value={inputValue}
            keyword={setSearchName}
          />
          <Image src={search_outline_24px} size={['20px', '20px']} />
        </SearchInplamInputContainer>
        <Observer>
          {
            () => {
              if (fanSpotList?.length > 0 && inputValue) {
                return (
                  <Flex
                    ref={scrollerRef}
                    type={'column'}
                    style={
                      {
                        height: '368px',
                        gap: '16px',
                        overflow: 'scroll',
                        marginTop: '16px',
                      }
                    }
                  >
                    {
fanSpotList?.map(val => (
  <FanSpotListItem
    key={val?._id}
    item={val}
    onClickItem={
      item => {
        history.push(`/fanspot/${item?.uniqueName}`)
        handleBottomModalClose()
      }
    }
  />
))
                    }
                  </Flex>
                )
              }
              if (fanSpotList?.length === 0 && !inputValue) {
                return (
                  <Flex
                    style={{ marginTop: '150px', marginBottom: '195px' }}
                    justify={'center'}
                  >
                    <Caption1 type={'Regular'} color={'#646464'}>
                    아티스트명을 입력해주세요.
                    </Caption1>
                  </Flex>
                )
              }
              return (
                <Flex
                  style={{ marginTop: '150px', marginBottom: '195px' }}
                  justify={'center'}
                >
                  <Caption1 type={'Regular'} color={'#646464'}>
                  검색 결과가 없습니다.
                  </Caption1>
                </Flex>
              )
            }
          }
        </Observer>
      </Flex>
    </BottomSheet>
  )
}

export default FanspotRequestListBottomModal

const SearchInplamInputContainer = styled(Flex)`
  width: 100%;
  height: 40px;

  border: none !important;
  background-color: #f4f4f4;

  box-sizing: border-box;
`
const SearchInplamInput = styled(Input)`
  flex: 1;
  font-size: 12px;
  border: none !important;
  background-color: transparent;
  padding: 11.5px 8px 11.5px 12px;
`

const FanSpotListItem = ({ item, onClickItem = () => {} }) => {
  return (
    <Flex
      onClick={
        () => {
          onClickItem(item)
        }
      }
      align={'center'}
      style={
        {
          cursor: 'pointer',
        }
      }
    >
      <Image
        src={
          item?.image64Path
            ? STORAGE_URL + item?.image64Path
            : default_profile_img
        }
        size={['32px', '32px']}
        style={
          {
            marginRight: '12px',
            borderRadius: '32px',
          // border: `solid 0.5px ${colors.inActive}`,
          }
        }
        onError={e => {}}
      />
      <H6>{item?.name || ''}</H6>
      {
        !!item?.inplamFavoriteInfo && (
          <Image
            src={star_fill_24}
            size={['20px', '20px']}
            style={{ marginLeft: '2px' }}
          />
        )
      }
    </Flex>
  )
}
