import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Caption1, H5 } from '@components/atoms'
import { move_btn_img, move_prev_btn_img } from '@images'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { SearchAlbumItem } from '@components/molecules'

/*
 * ./SearchArtistList 로직 동일
 */
const SearchAlbumList = ({
  showDetail,
  setIsSearch,
  searchAlbumList,
  keyword,
  firstFetchSearchDetail,
  moreFetchSearchDetail,
}) => {
  const [offset, setOffset] = useState(0)
  const [isHasMore, setIsHasMore] = useState(true)

  const countOffset = () => {
    setOffset(offset + 1)
  }

  const onLoadMore = async () => {
    if (offset < 1) {
      const res = await firstFetchSearchDetail('album', keyword)
      setIsHasMore(res)
    }
    else {
      const res = await moreFetchSearchDetail('album', keyword, offset)
      setIsHasMore(res)
    }
  }

  useEffect(() => {
    onLoadMore()
  }, [offset])

  return (
    <Flex type="column" style={{ padding: '0px 20px' }}>
      <Flex style={{ alignItems: 'center', height: '36px' }}>
        <H5 type="Bold" align="left">
          앨범
        </H5>
      </Flex>
      {
        searchAlbumList && searchAlbumList.length >= 1 ? (
          <InfiniteScroll
            dataLength={searchAlbumList.length}
            scrollThreshold={0.9}
            next={countOffset}
            hasMore={isHasMore}
            loader={
              <ReactLoading
                type="spin"
                color="black"
                style={{ width: '40px', height: '40px', margin: '0 auto' }}
              />
            }
            style={{ overflow: 'unset' }}
          // className="scroll_div"
          >
            {
              searchAlbumList.map((item, index) => {
                return (
                  <SearchAlbumItem
                    item={item}
                    key={index}
                    setIsSearch={setIsSearch}
                  />
                )
              })
            }
          </InfiniteScroll>
        ) : (
          <Caption1 align="left">검색 결과가 없습니다.</Caption1>
        )
      }
    </Flex>
  )
}

export default SearchAlbumList
