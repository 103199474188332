import React, { useEffect, useRef, useState } from 'react'
import { FanSpotRequestEventTemplate } from '@components/templates'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Flex, H6 } from '@components/atoms'
import { fluent_arrow_24 } from '@images/index'
import FanspotRequestListBottomModal from '@components/organisms/Popup/FanspotRequestListBottomModal'

const FanSpotRequestEventPage = ({ history }) => {
  const [isFanSpotModalOpen, setIsFanSpotModalOpen] = useState(false)

  return (
    <div style={{ position: 'relative', overflow: 'auto', height: '100vh' }}>
      <Helmet>
        <title>{`팬스팟 오픈 요청 이벤트 | 플램`}</title>
        <meta name="description" content={'PLAM 팬스팟 오픈 요청 이벤트'} />
        <meta
          name="keywords"
          content="팬스팟,부캐,아이돌,디스코그라피,음악,음반,앨범,음원유통,음원유통사,CD,아이돌,뮤지션,아티스트,프로필,music,album,linkfire,플램인,플램,plam,plamin"
        />
      </Helmet>
      <Header
        className={'header'}
        style={
          {
            backgroundColor: `rgba(255, 255, 255, 1)`,
          }
        }
        justify="space-between"
        align="center"
      >
        <Flex
          style={{ width: '44px', height: '44px', cursor: 'pointer' }}
          align="center"
          justify="center"
          onClick={
            () => {
              history.push('/')
            }
          }
        >
          <PrevButton src={fluent_arrow_24} />
        </Flex>
        <HeaderTitle
          style={
            {
              width: '100%',
              justifyContent: 'center',
            }
          }
          align={'center'}
        >
          <H6 style={{ alignSelf: 'center' }}>
            {'PLAM 팬스팟 오픈 요청 이벤트'}
          </H6>
        </HeaderTitle>
      </Header>
      <FanSpotRequestEventTemplate
        setIsFanSpotModalOpen={setIsFanSpotModalOpen}
        history={history}
      />
      <FanspotRequestListBottomModal
        isModalOpen={isFanSpotModalOpen}
        setIsFanSpotModalOpen={setIsFanSpotModalOpen}
        history={history}
      />
    </div>
  )
}

export default FanSpotRequestEventPage

const Header = styled(Flex)`
  height: 52px;
  padding-right: 8px;
  position: sticky;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  transition: background-color 0.3s ease; /* 배경색 부드럽게 전환 */
`

const HeaderTitle = styled(Flex)`
  color: #000;
  position: absolute;
  width: '100%';
  z-index: -1;
`

const PrevButton = styled.img`
  width: 32px;
  height: 32px;
`
