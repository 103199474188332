import { BodyWrapper, Flex, H5, Caption1, H6 } from '@components/atoms'
import { BurgerMenu, MyInPlamList } from '@components/organisms'
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '@colors/'
import { move_down_gray_img, prev_page_img, article_list_img } from '@images/'
import { SwitchBtn } from '@components/molecules'
import { fluent_arrow_24 } from '@images/index'

const ActivityArticleTemplate = ({
  type,
  history,
  myArticleList,
  myArticleListCount,
  artistInfoList,
  uniqueName,
  setUniqueName,
  offset,
  setOffset,
  isAlaram,
  handleAlaram,
  fetchUser,
}) => {
  const handleMoveDetail = async (_uniqueName, id) => {
    if (!id) return
    history.push({
      pathname: `/fanspot/${_uniqueName}/${id}`,
      search: '?offset=0&sortBy=createdAt',
      state: {
        fromActivity: type,
      },
    })

    setTimeout(async () => {
      await fetchUser()
    }, 200)
  }

  return (
    <>
      <BodyWrapper
        id="inplam_template"
        style={{ paddingTop: 0, paddingBottom: 64, minHeight: '100vh' }}
      >
        <TopBar>
          <PrevButton src={fluent_arrow_24} onClick={() => history.goBack()} />
          {type === 'myArticles' ? <H6>작성한 글</H6> : <H6>댓글 단 글</H6>}
        </TopBar>

        <Br />
        {
          myArticleList && myArticleList.length < 1 ? (
            <Flex type="column" align="center" justify="center">
              <img
                src={article_list_img}
                alt="no_content_icon"
                style={{ width: 40, height: 40, margin: '136px 0 8px 0' }}
              />
              {
                type === 'myArticles' ? (
                  <H6>작성한 글이 없습니다</H6>
                ) : (
                  <H6>댓글 단 글이 없습니다</H6>
                )
              }
            </Flex>
          ) : (
            <>
              <Header>
                {/* <Select
                  value={uniqueName}
                  onChange={e => setUniqueName(e.target.value)}
                >
                  <option value="">전체</option>
                  {
                    artistInfoList
                  && artistInfoList.length > 1
                  && artistInfoList.map(artist => (
                    <option key={artist.uniqueName} value={artist.uniqueName}>
                      {artist.name}
                    </option>
                  ))
                  }
                </Select> */}

                <Flex justify={'flex-end'} style={{ padding: '8px 0', flex: 1 }}>
                  <Caption1 style={{ marginRight: 8 }}>
                    {type === 'myArticles' ? '댓글 알림' : '답글 알림'}
                  </Caption1>
                  <SwitchBtn
                    isSwitchOn={isAlaram}
                    handleSwitch={() => handleAlaram(!isAlaram)}
                  />
                </Flex>
              </Header>
              <Br />
              <MyInPlamList
                offset={offset}
                artistArticleList={myArticleList}
                artistArticleListCount={myArticleListCount}
                handleMoveDetail={handleMoveDetail}
                handlePagination={page => setOffset(page)}
              />
            </>
          )
        }
      </BodyWrapper>
    </>
  )
}

export default ActivityArticleTemplate

const TopBar = styled(Flex)`
  padding-top: 16px;
  padding-bottom: 16px;
  height: 24px;
  background-color: white;
  align-items: center;
`

const PrevButton = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 6px;
  margin-left: -12px;
  cursor: pointer;
`

const Header = styled(Flex)`
  padding-top: 16px;
  padding-bottom: 16px;
  height: 18px;
  justify-content: space-between;
  background-color: white;
  align-items: center;
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-position-x: 68px;
  background-size: 12px;
  background-repeat: no-repeat;
  width: 80px;
  display: block;
  text-overflow: ellipsis;
  -webkit-padding-end: 12px;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`
