import { Flex, H6 } from '@components/atoms'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { share_img_black } from '@images/'
import { useStore } from '@utils/hooks'
import { colors } from '@colors/'
import { DOMAIN_URL } from '@consts/'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import copy from 'copy-to-clipboard'

import { useSnackBar, useTooltip } from '..'
import OpenInplamPopup from '../Popup/OpenInplamPopup'

const OpenInplamCard = ({
  artistId,
  inplamOpenRequest,
  subdomainInfo,
  uniqueName,
  currentPath,
  needAuth,
  onClickFanSpot,
}) => {
  const { authStore, artistStore, inplamStore } = useStore()

  const { View: CopiedView, toggleSnackBar: handleCopied } = useSnackBar()

  const { View: LeftView, toggleTooltip: leftToggle } = useTooltip()
  const { View: RightView, toggleTooltip: rightToggle } = useTooltip()

  const {
    isShowing: openPopupIsShowing,
    toggle: openPopupToggle,
  } = OpenInplamPopup.usePopup()

  const [accountedInfo, setAccountedInfo] = useState(null)
  const [localInplamOpenRequest, setLocalInplamOpenRequest] = useState(false)

  const handleAccountOpen = async () => {
    if (localInplamOpenRequest || inplamOpenRequest) {
      return
    }

    if (!authStore.currentUser) {
      needAuth()
      return
    }

    const accounted = await inplamStore.accountOpenRequests(artistId)
    if (!accounted) return

    setLocalInplamOpenRequest(true)
    setAccountedInfo(accounted)

    openPopupToggle()
  }

  const updatedOpen = async phone => {
    const res = await inplamStore.updateOpenRequests(
      accountedInfo._id,
      artistId,
      phone,
    )

    return res
  }

  useEffect(() => {
    if (!artistStore.isReadedOpenInplam) leftToggle()
    if (artistStore.isReadedOpenInplam && !artistStore.isReadedShareInplam)
      rightToggle()
  }, [artistStore.isReadedOpenInplam])

  return (
    <Flex
      justify="space-between"
      style={{ marginTop: 14, position: 'relative' }}
    >
      <OpenInplamPopup.View
        isShowing={openPopupIsShowing}
        toggle={openPopupToggle}
        updatedOpen={updatedOpen}
        onOk={
          async () => {
            const result = copy(
              `${
                subdomainInfo
              && subdomainInfo.subdomain
              && subdomainInfo.isAccepted
                  ? `${subdomainInfo.subdomain}.${DOMAIN_URL}`
                  : currentPath
              }`,
            )
            if (result) {
              handleCopied()
            }
          }
        }
      />
      {
        <RequestOpenBtn onClick={onClickFanSpot}>
          <H6>팬스팟</H6>
          {
            <Requested
              isRequested={localInplamOpenRequest || inplamOpenRequest}
              onClick={
                e => {
                  e.stopPropagation()
                  handleAccountOpen()
                }
              }
            >
              <H6 type={'Regular'}>
                {
                  `${
                    localInplamOpenRequest || inplamOpenRequest
                      ? '요청 완료'
                      : '오픈 요청'
                  }`
                }
              </H6>
            </Requested>
          }
        </RequestOpenBtn>
      }
      <CopyToClipboard
        text={
          // subdomainInfo && subdomainInfo.subdomain && subdomainInfo.isAccepted
          //   ? `${subdomainInfo.subdomain}.${DOMAIN_URL}`
          //   : currentPath
          `${DOMAIN_URL}/fanspot/${uniqueName}`
        }
        onCopy={() => handleCopied()}
      >
        <ShareBtn>
          <img src={share_img_black} alt="share_icon" />
        </ShareBtn>
      </CopyToClipboard>
      <LeftView
        text={`아티스트 전용 커뮤니티 팬스팟 오픈을 요청해주세요\n요청이 일정 수 이상 도달할 시 팬스팟이 오픈됩니다`}
        handleClose={
          () => {
            artistStore.setIsReadedOpenInplam(true)
          }
        }
      />
      <RightView
        position={'right'}
        text={`팬스팟 오픈 요청을 공유하세요\n더욱 빠르게 오픈 요청 수가 달성됩니다`}
        handleClose={() => artistStore.setIsReadedShareInplam(true)}
      />
      <CopiedView
        text="주소가 클립보드에 복사되었습니다."
        backgroundColor="#66bc6a"
      />
    </Flex>
  )
}

export default OpenInplamCard

const RequestOpenBtn = styled(Flex)`
  width: 100%;
  margin-right: 8px;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  cursor: pointer;
`

const Requested = styled(Flex)`
  display: flex;
  padding: 5.5px 13px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  opacity: 0.16;
  background: #242424;
  z-index: 10;
  color: #fafafa;

  ${({ isRequested }) =>
    !isRequested
    && css`
      opacity: 1;
      background: #242424;
      color: #fafafa;
    `}
`

const ShareBtn = styled(Flex)`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 6px;
  border: 1px solid var(--grey-grey_30, #f4f4f4);
  background: #fff;
  box-sizing: border-box;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`
