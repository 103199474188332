import React from 'react'
import { Flex, Caption1, H6 } from '@components/atoms'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  arrow_right_outline_24px,
  move_btn_img,
  point_img,
} from '@images/index'
import Image from './Image'

const PointBoxSkeleton = ({ item }) => {
  return (
    <UserCurrentPointBox
      type={'row'}
      justify={'space-between'}
      align={'center'}
    >
      <Flex align={'center'}>
        <Image
          src={point_img}
          size={['20px', '20px']}
          style={{ marginRight: '4px' }}
        />
        <H6
          style={
            {
              display: 'flex',
              flexDirection: 'row',
              whiteSpace: 'pre-wrap',
            }
          }
        >
          <Skeleton width={100} />
        </H6>
      </Flex>
      <Flex align={'center'}>
        <H6 type={'Bold'}>
          <Skeleton width={80} />
        </H6>
        <img
          src={arrow_right_outline_24px}
          alt={'move_point_btn'}
          style={
            {
              width: '20px',
              height: '20px',
              marginLeft: '2px',
            }
          }
        />
      </Flex>
    </UserCurrentPointBox>
  )
}

export default PointBoxSkeleton

const UserCurrentPointBox = styled(Flex)`
  padding: 14px 16px;

  width: calc(100% - 40px);
  height: 48px;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 16px;

  border-radius: 6px;
  border: solid 1px #f4f4f4;
  background: #ffffff;
  margin: 0px 20px;
  cursor: pointer;

  margin-top: 16px;
  margin-bottom: 32px;
`
