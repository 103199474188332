import { action, observable } from 'mobx'
import { appleAuthHelpers } from 'react-apple-signin-auth'
import { DOMAIN_URL } from '@consts/'
import createHash from 'create-hash'
import { v4 } from 'uuid'
import { persist } from 'mobx-persist'
import { POST_MESSAGES, sendWTAPostMessage } from '@utils/postMessage'
import { Store } from '.'
import { Network } from './networks'
import { UserModel, LikeModel, NoticePopupModel, BannerModel } from './models'
import NotificationModel from './models/NotificationModel'

const packageJson = require('../../package.json')

export default class AuthStore {
  @observable currentUser
  @observable jsonWebToken
  @observable likeInfo
  @observable duplicateUser
  @observable likedAristList

  @persist @observable isNeverShowLikeArtistPopup = false
  @persist @observable isNeverShowLikeTrackPopup = false

  @observable noticePopupList = [] // 공지팝업 리스트

  @observable bannerList = [] // 배너 리스트
  @observable notificationList = [] // 알림 리스트
  @observable notificationOffset = 0

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {
    this.jsonWebToken = null
    this.duplicateUser = null
    this.likeInfo = null
    this.likedAristList = null
  }

  @action.bound
  async initClient() {
    if (localStorage.getItem('plam_jwt_token')) {
      this.jsonWebToken = window['localStorage'].getItem('plam_jwt_token')
    }
    else if (sessionStorage.getItem('jwt_token')) {
      this.jsonWebToken = window['sessionStorage'].getItem('jwt_token')
    }
  }

  @action.bound
  async login(account, password, isAutoLogin = true) {
    return this.store.useLoading(async () => {
      let res = false

      if (account && password) {
        res = await this.network.authNetwork.postLogin({ account, password })
        if (!res?.data?.isLogin) return res
      }
      else if (this.jsonWebToken) {
        res = await this.network.authNetwork.postTokenLogin({
          jsonWebToken: this.jsonWebToken,
        })
      }
      if (!res) {
        this.logout()
        return
      }

      this.currentUser = new UserModel(this.store, res.data['user'])
      this.jsonWebToken = res.data['jsonWebToken']
      if (this.jsonWebToken) {
        window['sessionStorage'].setItem('jwt_token', this.jsonWebToken)
        if (isAutoLogin) {
          window['localStorage'].setItem('plam_jwt_token', this.jsonWebToken)
        }
      }

      if (res?.data?.isTempPassword) {
        window.location.href = '/auth/change-password'
      }

      return res
    })
  }

  @action.bound
  async socialLogin(platform) {
    if (platform === 'facebook') {
      location.href = await this.network.authNetwork.getLoginFacebook()
    }
    else if (platform === 'google') {
      location.href = await this.network.authNetwork.getLoginGoogle()
    }
    else if (platform === 'kakao') {
      location.href = await this.network.authNetwork.getLoginKakao()
    }
    else if (platform === 'apple') {
      const uuid = v4()
      const response = await appleAuthHelpers.signIn({
        authOptions: {
          clientId: 'plam.naivy.com',
          // clientId: 'com.plam.link.web-service',
          scope: 'email name',
          redirectURI: `https://${DOMAIN_URL}/login/apple`,
          state: '',
          nonce: createHash('sha256')
            .update(uuid)
            .digest('hex'),
          usePopup: true,
        },
        onError: error => console.error(error),
      })

      if (response) {
        location.href = `https://${DOMAIN_URL}/login/apple?idToken=${response?.authorization?.id_token}&nonce=${uuid}`
      }
      else {
        console.error('Error performing apple signin.')
      }
    }
  }

  @action.bound
  async socialAuth(platform, code) {
    return this.store.useLoading(async () => {
      if (!code) return false
      let res = false

      if (platform === 'facebook') {
        res = await this.network.authNetwork.getAuthFacebook(code)
      }
      else if (platform === 'kakao') {
        res = await this.network.authNetwork.getAuthKakao(code)
      }
      else if (platform === 'google') {
        res = await this.network.authNetwork.getAuthGoogle(code)
      }
      else if (platform === 'apple') {
        res = await this.network.authNetwork.getAuthApple(code)
      }

      // 인증 성공 후
      if (res.isLogin) {
        // 로그인 성공
        this.currentUser = new UserModel(this.store, res['user'])
        this.jsonWebToken = res['jsonWebToken']
        this.jsonWebToken
          && window['sessionStorage'].setItem('jwt_token', this.jsonWebToken)
        window['localStorage'].setItem('plam_jwt_token', this.jsonWebToken)
      }
      else if (this.jsonWebToken) {
        // 인증, 가입 후 플램 토큰 로그인
        res = await this.network.authNetwork.postTokenLogin({
          jsonWebToken: this.jsonWebToken,
        })
      }
      else {
        // 로그인 실패 ( 같은 이메일로 다른 소셜 계정 회원가입 이력 있는 경우)
        // 가입된 유저 정보 및 소셜매체 반환
        this.duplicateUser = await res.user
        res = await res.user
      }
      return !res.error
    })
  }

  @action.bound
  async logout() {
    return this.store.useLoading(async () => {
      window['sessionStorage'].removeItem('jwt_token')
      window['localStorage'].removeItem('plam_jwt_token')
      this.likedTrackList = []
      this.jsonWebToken = null
      this.currentUser = null
    })
  }

  @action.bound
  async fetchLike(pageId, loginUserId) {
    return this.store.useLoading(async () => {
      if (!pageId) return

      let res = false

      res = await this.network.authNetwork.getLike(pageId, loginUserId)

      this.likeInfo = new LikeModel(this.store, res.data)

      return res.data
    })
  }

  @action.bound
  async updateLike(updateType, userId, pageId, pageType) {
    return this.store.useLoading(async () => {
      if (!updateType || !pageId || !pageType) {
        return false
      }
      if (!userId) {
        return 'login required'
      }

      let res = false

      // 좋아요 추가 일때,
      if (updateType === 'add') {
        res = await this.network.authNetwork.postLike({
          userId,
          pageId,
          pageType,
        })
      }
      else if (updateType === 'delete') {
        // 좋아요 삭제 일때,
        res = await this.network.authNetwork.deleteLike({
          userId,
          pageId,
          pageType,
        })
      }

      if (!res) return
      this.likeInfo = new LikeModel(this.store, res.data)

      return res
    })
  }

  @action.bound
  updateUser = async params => {
    const res = await this.network.authNetwork.putUser(params)
    if (!res) return

    // this.currentUser = new UserModel(
    //   this.store,
    //   Object.assign(this.currentUser, res.data.user),
    // )

    return res
  }

  @action.bound
  fetchUserLikeArtistList = async ({ loginUserId }) => {
    const res = await this.network.authNetwork.getUserLikeArtist({
      loginUserId,
    })
    if (!res?.data?.artistList) return

    this.likedAristList = res?.data?.artistList

    return res
  }

  @action.bound
  fetchUserLikeTrackList = async ({
    loginUserId,
    offset = 0,
    limit = 10,
    sortBy,
  }) => {
    return this.store.useLoading(async () => {
      const option = {
        limit,
        offset,
        sortBy,
      }
      const res = await this.network.authNetwork.getUserLikeTrack({
        loginUserId,
        where: option,
      })
      if (!res?.data?.trackList) return

      return res
    })
  }

  @action.bound
  fetchPopularTrackList = async ({ offset = 0, limit = 10, sortBy }) => {
    // sortBy : 정렬기준, 기본값 : 'albumInfo.releasedAt'|'likeCount'|'title'
    // sort : -1(내림차순), 1(오름차순), 기본값 :-1(내림차순)
    // artistId : 발매 아티스트, 필수
    // type : 발매, 참여 구분 'release'|'participate', 기본값 release

    return this.store.useLoading(async () => {
      const option = {
        limit,
        offset,
        sortBy,
      }
      const res = await this.network.authNetwork.getPopularTrack({
        where: option,
      })
      if (!res?.data?.trackList) return

      return res
    })
  }

  @action.bound
  secession = async () => {
    if (!this.currentUser) return
    const res = await this.network.authNetwork.deleteUser({
      userObjectId: this.currentUser._id,
    })

    if (res) {
      this.logout()

      sendWTAPostMessage({
        window,
        navigator,
        postMessageData: POST_MESSAGES.WTA_DATA.LOGOUT,
      })
    }

    return res
  }

  @action.bound
  blockUser = async params => {
    if (!this.currentUser._id || !params.blockedUserId) return
    return this.store.useLoading(async () => {
      const res = await this.network.authNetwork.postBlockUser(
        this.currentUser._id,
        params?.blockedUserId,
        {
          isAnonymous: !!params?.isAnonymous,
        },
      )

      if (!res) return

      if (res.data && res.data.usersBlockUsers) {
        return 'completed'
      }
      if (res.error) {
        return 'error'
      }
    })
  }

  @action.bound
  async findAccount(email) {
    return this.store.useLoading(async () => {
      if (!email) return

      const res = await this.network.authNetwork.postAccount({ email })
      if (!res) return

      return res
    })
  }

  @action.bound
  async findPassword(params) {
    return this.store.useLoading(async () => {
      if (!params) return

      const res = await this.network.authNetwork.postPassword(params)
      if (!res) return

      return res
    })
  }

  @action.bound
  async changePassword(params) {
    return this.store.useLoading(async () => {
      if (!params) return

      const res = await this.network.authNetwork.putPassword(params)
      if (!res) return

      return res
    })
  }

  @action.bound
  async fetchNoticePopupList(params) {
    return this.store.useLoading(async () => {
      // if (!params) return

      const res = await this.network.authNetwork.getPopup(params)

      if (!res?.data?.popupList) return

      const neverShowNoticeList =
        JSON.parse(localStorage.getItem('neverShowNoticePopupList')) || []

      const filteredList = res?.data?.popupList?.filter(val => {
        const isBanned =
          neverShowNoticeList?.findIndex(bannedNotice => {
            return (
              bannedNotice?._id === val?._id
              && new Date().getTime()
                - new Date(bannedNotice?.bannedAt).getTime()
                <= 24 * 60 * 60 * 1000
            )
          }) > -1

        if (!isBanned) {
          return true
        }
        return false
      })

      const noticePopupList = filteredList?.map(
        val => new NoticePopupModel(this.store, val),
      )

      this.noticePopupList = noticePopupList

      return noticePopupList
    })
  }

  /**
   *
   * @param type
   *  OPEN_NEW_REWARD : 음감회 오픈 알림
      DAILY_PEDOMETER : 만보기 포인트 수령알림
      DAILY_ATTENDANCE : 출석체크 알림
      NEW_ALBUM : 앨범 말매 알림
      MELON_LISTENER_NEW_TRACK : 발매 트랙 리스너 집계 내역 알림
      MELON_LISTENER_INCREASE_TRACK : 트랙 리스너 증가 알림
   */
  @action.bound
  fetchNotificationList = async (params: {
    __offset?: Number,
    __limit?: Number,
    __sortBy?: string,
    __sort?: Number,
    type?: string,
  }) => {
    const res = await this.network.authNetwork.getNotiList({ where: params })

    if (!res?.alertNotificationList || res?.alertNotificationList?.length === 0)
      return false

    if (params.__offset === 0) {
      this.notificationList = res.alertNotificationList
        .filter(elem => !!elem)
        .map(elem => new NotificationModel(this.store, elem))

      this.notificationOffset = 0
    }
    else if (params.__offset > 0) {
      this.notificationList = this.notificationList.concat(
        (this.notificationList = res.alertNotificationList
          .filter(elem => !!elem)
          .map(elem => new NotificationModel(this.store, elem))),
      )

      this.notificationOffset = params.__offset
    }

    return res.alertNotificationList
  }

  @action.bound
  fetchBannerList = async () => {
    const res = await this.network.authNetwork.getBannerList({
      options: {
        headers: {
          'plam-web-version': packageJson.version,
          'plam-platform': 'web',
        },
      },
    })

    if (!res?.bannerList || res?.bannerList?.length === 0) return false

    this.bannerList = res.bannerList
      .filter(elem => !!elem)
      .map(elem => new BannerModel(this.store, elem))

    return res.alertNotificationList
  }
}
