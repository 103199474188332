import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex, Caption1, H5 } from '@components/atoms'
import { move_btn_img, move_prev_btn_img } from '@images'
import { Link } from 'react-router-dom'
import { default_album_img } from '@images/'
import { observer, inject, Observer } from 'mobx-react'
import {
  SearchTrackItem,
  SearchAlbumItem,
  SearchArtistItem,
  SearchFanSpotItem,
  SearchArticleItem,
  SegmentedControl,
} from '@components/molecules'
import { arrow_right_outline_24px } from '@images/index'
import SearchArtistList from './SearchArtistList'
import SearchAlbumList from './SearchAlbumList'
import SearchTrackList from './SearchTrackList'
import SearchFanSpotList from './SearchFanSpotList'

const Section = styled(Flex)`
  flex-direction: column;
  width: 100%;
  /* margin-top: 36px; */
`
const Category = styled(Flex)`
  flex-direction: column;
  padding: 0px 20px;
`

const Br = styled.div`
  width: 100%;
  height: 8px;
  flex-shrink: 0;
  background: var(--grey-grey_10, #fafafa);

  border-top: solid 1px #f4f4f4;

  margin: 20px 0;
`

const List = styled(Flex)`
  flex-direction: column;
`

const RowList = styled(Flex)`
  flex-direction: row;

  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  /* > div:first-child {
    padding-left: 16px;
  }
  > div:last-child {
    padding-right: 0px;
  } */
  > div {
    /* padding: 6px 0; */
    cursor: pointer;
  }
`

//  검색 결과 리스트 컴포넌트
const SearchResultList = ({ searchStore }) => {
  /*
   * 더보기 분기 상태
   * : 'total', 'fanspot', 'artist', 'album', 'track'
   */

  const searchKeyword = searchStore.searchKeyword || null
  const more = searchStore.more || null
  const updateIsSearchMode = searchStore.updateIsSearchMode || (() => {})
  const setMore = searchStore.setMore || (() => {})

  const searchList = searchStore.searchList || null
  const autocompleteKeyword = searchStore.autocompleteKeyword || null

  const searchFanSpotList = searchStore.searchFanSpotList || null
  const searchArticleList = searchStore.searchArticleList || null
  const searchArtistList = searchStore.searchArtistList || null
  const searchAlbumList = searchStore.searchAlbumList || null
  const searchTrackList = searchStore.searchTrackList || null

  const firstFetchSearchDetail =
    searchStore.firstFetchSearchDetail || (() => {})
  const moreFetchSearchDetail = searchStore.moreFetchSearchDetail || (() => {})

  const [currentIndex, setCurrentIndex] = useState(0)

  const showDetail = type => {
    if (more === type) {
      setMore('total')
    }
    else {
      setMore(type)
    }
  }

  const filterData = [
    {
      label: '전체',
      value: 'total',
      ref: useRef(),
    },
    {
      label: '팬스팟',
      value: 'fanspot',
      ref: useRef(),
    },
    {
      label: '아티스트',
      value: 'artist',
      ref: useRef(),
    },
    {
      label: '트랙',
      value: 'track',
      ref: useRef(),
    },
    {
      label: '앨범',
      value: 'album',
      ref: useRef(),
    },
  ]

  const handleChangeFilter = _filter => {
    showDetail(_filter)
  }

  useEffect(() => {
    const index = filterData?.findIndex(val => val.value === more)
    setCurrentIndex(index)
  }, [more])

  return (
    <Flex type={'column'}>
      <Flex
        type={'column'}
        style={{ padding: '0 20px', marginTop: '8px', marginBottom: '16px' }}
      >
        <SegmentedControl
          name="filter"
          callback={
            val => {
              handleChangeFilter(val)
            }
          }
          value={currentIndex}
          segments={filterData}
        />
      </Flex>
      <Observer>
        {
          () => {
            if (more === 'total') {
              return (
                <Section>
                  <Category
                    type="column"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <Flex
                      style={
                        {
                          alignItems: 'center',
                          padding: '0 20px',
                          height: '36px',
                        }
                      }
                      justify={'space-between'}
                    >
                      <H5 type="Bold" align="left">
                      팬스팟
                      </H5>
                      <Flex
                        align={'center'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showDetail('fanspot')}
                      >
                        <Caption1 color={'#646464'} type={'Regular'}>
                        더보기
                        </Caption1>
                        <img
                          src={arrow_right_outline_24px}
                          style={{ widht: '12px', height: '12px' }}
                        />
                      </Flex>
                    </Flex>
                    {
searchList?.fanspotList?.length > 0 && (
                        <RowList
                          style={
                            {
                              gap: '12px',
                              padding: '0 20px',
                              margin: '10px 0',
                              display:
                          searchList?.fanspotList?.length === 0
                            ? 'none'
                            : 'flex',
                            }
                          }
                        >
                          {
                            searchList.fanspotList
                        && searchList.fanspotList.length >= 1
                        && searchList.fanspotList.map((item, index) => {
                          return (
                            <SearchFanSpotItem
                              item={item}
                              key={item._id}
                              setIsSearch={updateIsSearchMode}
                            />
                          )
                        })
                          }
                        </RowList>
                      )
                    }
                    {
searchList?.articleList?.length > 0 && (
                        <List
                          style={
                            {
                              display:
                          searchList?.articleList?.length === 0
                            ? 'none'
                            : 'flex',
                            }
                          }
                        >
                          {
                            searchList.articleList
                        && searchList.articleList.length >= 1
                        && searchList.articleList.map((item, index) => {
                          return (
                            <SearchArticleItem
                              item={item}
                              key={item._id}
                              setIsSearch={updateIsSearchMode}
                            />
                          )
                        })
                          }
                        </List>
                      )
                    }
                    {
searchList?.articleList?.length === 0
                    && searchList.fanspotList?.length === 0 && (
                        <Flex style={{ padding: '0 20px' }}>
                          <Caption1 style={{ padding: '6px 0' }} align="left">
                          검색 결과가 없습니다.
                          </Caption1>
                        </Flex>
                      )
                    }
                  </Category>
                  <Br />
                  <Category type="column">
                    <Flex
                      style={{ alignItems: 'center', height: '36px' }}
                      justify={'space-between'}
                    >
                      <H5 type="Bold" align="left">
                      아티스트
                      </H5>
                      <Flex
                        align={'center'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showDetail('artist')}
                      >
                        <Caption1 color={'#646464'} type={'Regular'}>
                        더보기
                        </Caption1>
                        <img
                          src={arrow_right_outline_24px}
                          style={{ widht: '12px', height: '12px' }}
                        />
                      </Flex>
                    </Flex>
                    <List>
                      {
                        searchList.artistList
                    && searchList.artistList.length >= 1 ? (
                            searchList.artistList.map((item, index) => {
                              return (
                                <SearchArtistItem
                                  item={item}
                                  key={item._id}
                                  setIsSearch={updateIsSearchMode}
                                />
                              )
                            })
                          ) : (
                            <Caption1 align="left">검색 결과가 없습니다.</Caption1>
                          )
                      }
                    </List>
                  </Category>
                  <Br />

                  <Category type="column">
                    <Flex
                      style={{ alignItems: 'center', height: '36px' }}
                      justify={'space-between'}
                    >
                      <H5 type="Bold" align="left">
                      트랙
                      </H5>
                      <Flex
                        align={'center'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showDetail('track')}
                      >
                        <Caption1 color={'#646464'} type={'Regular'}>
                        더보기
                        </Caption1>
                        <img
                          src={arrow_right_outline_24px}
                          style={{ widht: '12px', height: '12px' }}
                        />
                      </Flex>
                    </Flex>
                    <List>
                      {
                        searchList.trackList
                    && searchList.trackList.length >= 1 ? (
                            searchList.trackList.map((item, index) => {
                              return (
                                <SearchTrackItem
                                  key={item._id}
                                  item={item}
                                  setIsSearch={updateIsSearchMode}
                                />
                              )
                            })
                          ) : (
                            <Caption1 align="left">검색 결과가 없습니다.</Caption1>
                          )
                      }
                    </List>
                  </Category>
                  <Br />

                  <Category type="column">
                    <Flex
                      style={{ alignItems: 'center', height: '36px' }}
                      justify={'space-between'}
                    >
                      <H5 type="Bold" align="left">
                      앨범
                      </H5>
                      <Flex
                        align={'center'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => showDetail('album')}
                      >
                        <Caption1 color={'#646464'} type={'Regular'}>
                        더보기
                        </Caption1>
                        <img
                          src={arrow_right_outline_24px}
                          style={{ widht: '12px', height: '12px' }}
                        />
                      </Flex>
                    </Flex>
                    <List>
                      {
                        searchList.albumList
                    && searchList.albumList.length >= 1 ? (
                            searchList.albumList.map((item, index) => {
                              return (
                                <SearchAlbumItem
                                  item={item}
                                  key={item._id}
                                  setIsSearch={updateIsSearchMode}
                                />
                              )
                            })
                          ) : (
                            <Caption1 align="left">검색 결과가 없습니다.</Caption1>
                          )
                      }
                    </List>
                  </Category>
                </Section>
              )
            }
            if (more === 'fanspot') {
              return (
                <Section>
                  <SearchFanSpotList
                    showDetail={showDetail}
                    setIsSearch={updateIsSearchMode}
                    searchFanSpotList={searchFanSpotList}
                    searchArticleList={searchArticleList}
                    keyword={searchKeyword}
                    firstFetchSearchDetail={firstFetchSearchDetail}
                    moreFetchSearchDetail={moreFetchSearchDetail}
                  />
                </Section>
              )
            }
            if (more === 'artist') {
              return (
                <Section>
                  <SearchArtistList
                    showDetail={showDetail}
                    setIsSearch={updateIsSearchMode}
                    searchArtistList={searchArtistList}
                    keyword={searchKeyword}
                    firstFetchSearchDetail={firstFetchSearchDetail}
                    moreFetchSearchDetail={moreFetchSearchDetail}
                  />
                </Section>
              )
            }
            if (more === 'track') {
              return (
                <Section>
                  <SearchTrackList
                    showDetail={showDetail}
                    setIsSearch={updateIsSearchMode}
                    searchTrackList={searchTrackList}
                    keyword={searchKeyword}
                    firstFetchSearchDetail={firstFetchSearchDetail}
                    moreFetchSearchDetail={moreFetchSearchDetail}
                  />
                </Section>
              )
            }
            if (more === 'album') {
              return (
                <Section>
                  <SearchAlbumList
                    showDetail={showDetail}
                    setIsSearch={updateIsSearchMode}
                    searchAlbumList={searchAlbumList}
                    keyword={searchKeyword}
                    firstFetchSearchDetail={firstFetchSearchDetail}
                    moreFetchSearchDetail={moreFetchSearchDetail}
                  />
                </Section>
              )
            }

            return null
          }
        }
      </Observer>
    </Flex>
  )
}

export default inject('searchStore')(observer(SearchResultList))
