import { Caption1, Caption2, Flex, H6 } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import {
  converseCommentUnitEng,
  convertToPricingComma,
  dateFromString,
  dateTodayYMDMM,
} from '@utils/format'
import { auth_artist_img, upload_photo_img } from '@images/'
import { STORAGE_URL } from '@consts'
import {
  comments_fill_24,
  notice_img,
  sns_instagram,
  sns_twitter,
  sns_youtube,
  thumbup_fill_24_off,
  thumbup_fill_24_on,
  view_fill_24px,
} from '@images/index'

import { useHistory } from 'react-router-dom'

import qs from 'qs'
import Image from './Image'

const SnsIconComponenet = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  if (!text) {
    return null
  }

  const icons = text.split(urlRegex).map(url => {
    const query = qs.parse(url, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })

    if (url.indexOf('youtube') !== -1) {
      // if (url.indexOf('youtube') !== -1) {
      if (query[Object.keys(query)[0]]) {
        return <SnsImg src={sns_youtube} />
      }
    }
    if (url.indexOf('youtu.be') !== -1) {
      // if (!query[Object.keys(query)[0]] && url.indexOf('youtu.be') !== -1) {

      return <SnsImg src={sns_youtube} />
    }

    // 인스타그램 링크 처리 (임베드 공유 복사)
    if (
      url.includes('instagram.com/p/')
      && url.includes('?utm_source=ig_embed')
    ) {
      return <SnsImg src={sns_instagram} />
    }

    // 인스타그램 링크 처리 (임베드 링크 복사)
    if (url.includes('instagram.com/p/')) {
      return <SnsImg src={sns_instagram} />
    }

    // X (구 Twitter) 링크 처리
    if (
      url.includes('twitter.com')
      || (url.includes('x.com') && url.includes('?ref_src=twsrc'))
    ) {
      return <SnsImg src={sns_twitter} />
    }

    // X (구 Twitter) 링크 처리
    if (url.includes('twitter.com') || url.includes('x.com')) {
      return <SnsImg src={sns_twitter} />
    }

    return null
  })

  return icons.filter(val => !!val)?.[0] || null
}

const SearchArticleItem = ({ item, setIsSearch }) => {
  const history = useHistory()

  if (item) {
    return (
      <ItemBox
        onClick={
          () => {
            history.push(
              `/fanspot/${item?.artistInfo?.uniqueName}/${
              item?._id
              }?offset=${0}&sortBy=${'createdAt'}`,
            )
            setIsSearch(false)
          }
        }
      >
        <Flex>
          <Flex
            type="column"
            style={
              {
                flex: 1,
                width: 1,
                marginRight: 8,
              }
            }
          >
            <Flex
              style={{ marginBottom: 4 }}
              align="center"
              justify={'space-between'}
            >
              <Flex align="center">
                <Caption2 type="Regular" color={colors.brown_grey}>
                  {item?.artistName}
                </Caption2>
                {
                  !item.isAnonymous && item?.isOfficial && (
                    <FromArtistImg src={auth_artist_img} alt="from_artist_icon" />
                  )
                }
              </Flex>
              <Caption2 type={'Regular'} color={'#949494'}>
                {dateFromString(item.createdAt)}
              </Caption2>
            </Flex>
            <Flex justify={'space-between'}>
              <Flex type={'column'}>
                <Flex type={'column'}>
                  <Flex align="center">
                    {/* {
                item.writerId === item.artistUserId && (
                  <FromArtistImg src={auth_artist_img} alt="from_artist_icon" />
                )
              } */}
                    <TitleText>{item.title}</TitleText>
                    <SnsIconComponenet text={item?.text} />
                  </Flex>
                  <BodyText type={'Regular'}>{item?.text}</BodyText>
                </Flex>
              </Flex>
              {
                item.imageOriginalPathList
                && item.imageOriginalPathList.length > 0 && (
                  <ThumbnailBox>
                    <img
                      src={
                        STORAGE_URL
                        + (item.image480PathList && item.image480PathList[0])
                      }
                      onError={
                        e => {
                          e.target.src = upload_photo_img
                        }
                      }
                      alt="exist_img"
                      style={
                        {
                          width: '42px',
                          height: '42px',
                          objectFit: 'cover',
                        }
                      }
                    />
                  </ThumbnailBox>
                )
              }
            </Flex>
          </Flex>
        </Flex>
      </ItemBox>
    )
  }

  return <></>
}

export default SearchArticleItem

const ItemBox = styled(Flex)`
  width: calc(100%);
  padding: 8px 20px 8px 20px;
  /* border-bottom: solid 1px ${colors.whitef4}; */
  box-sizing: border-box;
  /* height: 106px; */
  cursor: pointer;
  flex-direction: column;
`

const TitleText = styled(H6)`
  width: fit-content;
  max-width: 100%;
  line-height: 24px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`
const BodyText = styled(H6)`
  width: fit-content;
  max-width: 100%;
  line-height: 24px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const CommentCountBox = styled(Flex)`
  flex-direction: column;
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  padding: 6px 9px;
  background-color: ${colors.whitef4};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const ThumbnailBox = styled(Flex)`
  flex-direction: column;
  max-width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  margin-left: 8px;
`

const FromArtistImg = styled.img`
  width: 12px;
  height: 12px;
`

const SnsImg = styled.img`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  object-fit: cover;
  margin-left: 8px;
`
const Br = styled(Flex)`
  margin-left: 0px;
  width: calc(100% + 0px);
  border-bottom: solid 1px ${colors.whitef4};
  margin-top: 24px;
`
