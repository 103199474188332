import React from 'react'
import { STORAGE_URL } from '@consts'
import Image from './Image'

const HomeEventBannerListItem = ({ event, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      role="button"
      tabIndex="0"
      onKeyDown={
        e => {
        // if (e.key === 'Enter' || e.key === ' ') {
        // handleClick()
        // }
        }
      }
      style={{ borderRadius: '4px', overflow: 'hidden', cursor: 'pointer' }}
    >
      <img
        src={`${STORAGE_URL}${event.image512Path}`}
        alt={'banner'}
        // size={['320px', '64px']}
        style={
          {
            width: 'calc(100vw - 36px)',
            height: 'auto',
            maxWidth: '384px',
          }
        }
      />
    </div>
  )
}

export default HomeEventBannerListItem
