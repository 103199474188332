import React, { useState } from 'react'
import { BurgerMenu, SearchResultList } from '@components/organisms'
import { BodyWrapper } from '@components/atoms'

/*
 * 검색을 했을 때, 페이지
 */
const SearchTemplate = () => {
  const [isOpen, setIsOpen] = useState(false) // 햄버거

  return (
    <>
      <BurgerMenu isOpen={isOpen} setIsOpen={setIsOpen} isHome />
      <BodyWrapper
        style={{ paddingLeft: 0, paddingRight: 0, paddingTop: '56px' }}
      >
        <SearchResultList />
      </BodyWrapper>
    </>
  )
}

export default SearchTemplate
