import { Caption1, Flex, H6 } from '@components/atoms'
import React from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import { InPlamListItem } from '@components/molecules'
import {
  move_down_gray_img,
  last_page_img,
  no_last_page_img,
  first_page_img,
  no_first_page_img,
} from '@images/'
import Pagination from 'react-js-pagination'

const MyInPlamList = ({
  style,
  offset,
  handlePagination,
  artistArticleList,
  artistArticleListCount,
  handleMoveDetail,
}) => {
  return (
    <Flex type="column" style={style && style}>
      <Flex type="column" style={{ gap: '24px', paddingTop: '24px' }}>
        {
          artistArticleList
          && artistArticleList.map(item => (
            <ItemBox key={item._id}>
              {item.notificationCount > 0 && <DotBadge />}
              <InPlamListItem
                item={item}
                key={item._id}
                onClick={() => handleMoveDetail(item.uniqueName, item._id)}
                category={'all'}
              />
            </ItemBox>
          ))
        }
      </Flex>
      <Pagination
        activePage={offset + 1}
        itemsCountPerPage={10}
        totalItemsCount={artistArticleListCount || 0}
        pageRangeDisplayed={5}
        onChange={
          page => {
            handlePagination(page - 1)
          }
        }
        innerClass="pagination-ul"
        activeClass="current-page"
        itemClass="pagination-li"
        // hideFirstLastPages
        hideNavigation
        // prevPageText={false}
        // nextPageText={false}
        firstPageText={
          <PrevNextPage
            src={offset + 1 !== 1 ? first_page_img : no_first_page_img}
          />
        }
        lastPageText={
          <PrevNextPage
            src={
              offset + 1 !== Math.ceil(artistArticleListCount / 10)
                ? last_page_img
                : no_last_page_img
            }
          />
        }
      />
    </Flex>
  )
}

export default MyInPlamList

const PrevNextPage = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`

const ItemBox = styled.div`
  position: relative;
  display: inline-block;
`

const DotBadge = styled.span`
  position: absolute;
  top: 8px;
  left: -8px;
  height: 4px;
  width: 4px;
  background-color: ${colors.lightish_red};
  border-radius: 50%;
  display: inline-block;
`
