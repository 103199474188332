import React, { useState, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { Flex, BodyWrapper, H6, Caption1, Caption2 } from '@components/atoms'
import {
  Image,
  InplamArtistListItem,
  InplamArtistListItemSkeleton,
  PointBoxSkeleton,
} from '@components/molecules'
import {
  LoginPopup,
  BurgerMenu,
  LatestAlbumList,
  PlamChart,
  Footer,
  HomeInPlamList,
  ProgressingRewardList,
  AppDownloadPopup,
  LikePopup,
  NoticePopup,
  FanspotListBottomModal,
  HomeEventBannerList,
} from '@components/organisms'
import { Observer, observer } from 'mobx-react'

import { move_btn_img_white, contact_banner } from '@images'
import LikeArtistList from '@components/organisms/List/LikeArtistList'
import PopularTrackList from '@components/organisms/List/PopularTrackList'
import LikeTrackList from '@components/organisms/List/LikeTrackList'
import {
  arrow_right_outline_24px,
  contact_banner2,
  fluent_arrow_24,
  move_btn_img,
  point_img,
} from '@images/index'
import {
  POST_MESSAGES,
  getIsWebApp,
  sendWTAPostMessage,
} from '@utils/postMessage'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import TotalInplamArtistScoreList from '@components/organisms/List/TotalInplamArtistScoreList'

const ContactBanner = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 13px 20px;
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  background-image: linear-gradient(
    to right,
    #f78ca0 0%,
    #f9748f 19%,
    #fd868c 60%,
    #fe9a8b 100%
  );
`

const HomeInplamArtistList = styled(Flex)`
  position: relative;
  margin-left: -8px;
  flex-wrap: wrap;
  div + div {
    margin: 0 9px 12px 9px;
  }
  div:first-child {
    margin: 0px 9px;
  }

  max-height: 112px;
  overflow: hidden;
`
const UserCurrentPointBox = styled(Flex)`
  padding: 14px 16px;

  width: calc(100% - 40px);
  height: 48px;
  overflow: hidden;
  box-sizing: border-box;

  border-radius: 6px;
  border: solid 1px #f4f4f4;
  background: #ffffff;
  margin: 16px 20px;
  cursor: pointer;

  margin-bottom: 8px;
  margin-top: 16px;
`

const BottomGradient = styled.div`
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`

const HomeTemplate = ({
  likedAristList,
  likedTrackList,
  popularTrackList,
  noticePopupList,
  currentUser,
  fetchPlamChart,
  latestAlbumList,
  rewardList,
  plamChartList,
  fetchRewardList,
  popularArticleList,
  fetchPopularArticleList,
  totalInplamList,
  history,
  onPressMoreTrackList,
  onPressMorePopularTrackList,
  likedTrackSortBy,
  handleChangeLikedTrackSortBy,
  popularTrackSortBy,
  handleChangePopularTrackSortBy,
  setIsFavorite,
  isFavorite,
  setTotalInplamOffset,
  more,
  fetchTotalList,
  totalInplamoffset,
  keyword,
  setKeyword,
  fanSpotList,
  bannerList,
  notice,
}) => {
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false) // 로그인 상태
  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [showDownloadPopup, setShowDownloadPopup] = useState(false) // 앱 다운로드 팝업
  const [showNoticePopup, setShowNoticePopup] = useState(false) // 공지사항 팝업
  const { authStore, loadingStore, articleStore } = useStore()
  const { isInitialLoading, handlerObj, isLoading } = loadingStore

  const [isFanSpotModalOpen, setIsFanSpotModalOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const debouncedOnChange = useRef(
    _.debounce(value => {
      setKeyword(value)
      if (totalInplamoffset > 1) {
        setTotalInplamOffset(0)
      }
    }, 300),
  ).current

  const handleSearch = value => {
    setInputValue(value)
    debouncedOnChange(value)
  }

  const handleArticleLike = item => {
    if (!(authStore.currentUser && authStore.currentUser._id)) {
      setIsShowLoginPopup(true)
      return
    }

    if (item.isLike) {
      articleStore.updateArticleLike('delete', item)
    }
    else if (!item.isLike) {
      articleStore.updateArticleLike('add', item)
    }
  }

  useEffect(() => {
    const isWebApp = getIsWebApp({ navigator })

    if (noticePopupList?.length > 0 && !isWebApp) {
      setShowNoticePopup(true)
    }
  }, [noticePopupList])

  return (
    <>
      <BurgerMenu isHome isOpen={isOpen} setIsOpen={setIsOpen} />
      <BodyWrapper
        style={{ background: '#f4f4f4', minHeight: '100vh', padding: 0 }}
      >
        {
          isShowLoginPopup && (
            <LoginPopup handleClose={() => setIsShowLoginPopup(false)} />
          )
        }
        {
          showDownloadPopup && (
            <AppDownloadPopup handleClose={() => setShowDownloadPopup(false)} />
          )
        }
        {
          showNoticePopup && (
            <NoticePopup
              noticePopupList={noticePopupList}
              handleClose={() => setShowNoticePopup(false)}
              type={'notice'}
            />
          )
        }
        <Flex type="column" style={{ padding: '56px 0 0 0' }}>
          <Observer>
            {
              () => {
                if (authStore?.jsonWebToken) {
                  if (isInitialLoading) {
                    return <PointBoxSkeleton />
                  }

                  return (
                    <UserCurrentPointBox
                      type={'row'}
                      justify={'space-between'}
                      align={'center'}
                      onClick={
                        () => {
                          const isWebApp = getIsWebApp({ navigator })
                          if (isWebApp) {
                            sendWTAPostMessage({
                              window,
                              navigator,
                              postMessageData:
                            POST_MESSAGES.WTA_DATA.MOVE_POINT_HISTORY,
                            })
                          }
                          else {
                            setShowDownloadPopup(true)
                          }
                        }
                      }
                    >
                      <Flex align={'center'}>
                        <Image
                          src={point_img}
                          size={['20px', '20px']}
                          style={{ marginRight: '4px' }}
                        />
                        <H6
                          style={
                            {
                              display: 'flex',
                              flexDirection: 'row',
                              whiteSpace: 'pre-wrap',
                            }
                          }
                          color={'#646464'}
                        >
                          {`보유 포인트`}
                        </H6>
                      </Flex>
                      <Flex align={'center'}>
                        <H6 type={'Bold'}>
                          {
                            ` ${convertToPricingComma(
                          currentUser?.currentPoint || 0,
                            )}P`
                          }
                        </H6>
                        <img
                          src={arrow_right_outline_24px}
                          alt={'move_point_btn'}
                          style={
                            {
                              width: '20px',
                              height: '20px',
                              marginLeft: '2px',
                            }
                          }
                        />
                      </Flex>
                    </UserCurrentPointBox>
                  )
                }
                return null
              }
            }
          </Observer>
          <HomeInPlamList
            fetchPopularArticleList={fetchPopularArticleList}
            popularArticleList={popularArticleList}
            handleArticleLike={handleArticleLike}
            setIsFanSpotModalOpen={setIsFanSpotModalOpen}
            setIsShowLoginPopup={setIsShowLoginPopup}
            notice={notice}
          />

          {
            (bannerList?.length > 0 || isInitialLoading) && (
              <HomeEventBannerList
                bannerList={bannerList}
                setShowDownloadPopup={setShowDownloadPopup}
              />
            )
          }

          {
            (rewardList?.length > 0 || isInitialLoading) && (
              <ProgressingRewardList
                progressingRewardList={rewardList}
                fetchRewardList={fetchRewardList}
                setShowDownloadPopup={setShowDownloadPopup}
              />
            )
          }

          {
            <Observer>
              {
                () => {
                  const isMyFanspotList =
                  totalInplamList?.findIndex(val => val?.isInplamFavorite) > -1

                  const dataList = totalInplamList

                  if (totalInplamList?.length > 0 || isInitialLoading) {
                    return (
                      <LikeArtistList
                        isMyFanspotList={isMyFanspotList}
                        list={dataList}
                        isLoading={isInitialLoading}
                      />
                    )
                  }
                  return null
                }
              }
            </Observer>
          }

          {
            (fanSpotList?.length > 0 || keyword?.length > 0) && (
              <TotalInplamArtistScoreList
                list={fanSpotList}
                setOffset={setTotalInplamOffset}
                offset={totalInplamoffset}
                fetchTotalList={fetchTotalList}
                isFavorite={isFavorite}
                more={more}
                onChange={e => handleSearch(e.target.value)}
                inputValue={inputValue}
                keyword={keyword}
              />
            )
          }

          {
currentUser?.type === 'company' && (
              <LatestAlbumList latestAlbumList={latestAlbumList} />
            )
          }

          {/* <PlamChart
            fetchPlamChart={fetchPlamChart}
            plamChartList={plamChartList}
          /> */}
        </Flex>
        <Observer>
          {
            () => {
              if (
              currentUser?.type === 'company'
              && ((currentUser && likedTrackList?.length > 0)
                || (currentUser && isInitialLoading))
              ) {
                return (
                  <LikeTrackList
                    onPressMoreTrackList={onPressMoreTrackList}
                    list={likedTrackList}
                    isLoading={isInitialLoading}
                    likedTrackSortBy={likedTrackSortBy}
                    handleChangeLikedTrackSortBy={handleChangeLikedTrackSortBy}
                  />
                )
              }
              return null
            }
          }
        </Observer>
        <Observer>
          {
            () => {
              if (
              currentUser?.type === 'company'
              && (popularTrackList?.length > 0 || isInitialLoading || isLoading)
              ) {
                return (
                  <PopularTrackList
                    onPressMorePopularTrackList={onPressMorePopularTrackList}
                    list={popularTrackList}
                    isLoading={isInitialLoading}
                    popularTrackSortBy={popularTrackSortBy}
                    handleChangePopularTrackSortBy={
                      handleChangePopularTrackSortBy
                    }
                  />
                )
              }
              return null
            }
          }
        </Observer>
      </BodyWrapper>
      {/* <ContactBanner>
        <H6 color="#ffffff">아티스트 회원 가입하고, 내 데이터 수집하기!</H6>
        <Flex
          style={
            {
              alignItems: 'center',
              marginTop: '8px',
              cursor: 'pointer',
            }
          }
        >
          <Caption1 type="Regular">
            <a
              style={{ color: '#ffffff' }}
              href={'https://plam.kr/artist'}
              target="_blank"
              rel="noopener noreferrer"
            >
              아티스트 등록하기
            </a>
          </Caption1>
          <img
            src={move_btn_img_white}
            style={{ width: '12px', height: '12px' }}
            alt="move_icon"
          />
        </Flex>
      </ContactBanner> */}

      {/* 배너 */}
      {/* <a
        style={{ color: '#ffffff' }}
        href={'https://forms.gle/KoWFTKtXEpvaf5Av7'}
        target="_blank"
      >
        <img
          src={contact_banner2}
          style={{ width: '420px' }}
          alt="contact_banner"
        />
      </a> */}
      <Footer />
      <FanspotListBottomModal
        isModalOpen={isFanSpotModalOpen}
        setIsFanSpotModalOpen={setIsFanSpotModalOpen}
        history={history}
        isHome
      />
    </>
  )
}

export default observer(HomeTemplate)
