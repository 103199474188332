import React from 'react'
import { Flex, Caption1, H5, Caption2, H6 } from '@components/atoms'
import { colors } from '@colors'
import moment from 'moment'
import { STORAGE_URL } from '@consts'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const NotificationItem = ({
  item,
  index,
  onClickNotificationItem = () => {},
}) => {
  const dateFromString = date => {
    const diffDay = moment().diff(moment(date), 'days')
    const diffTime = moment().diff(moment(date), 'minute')

    if (diffDay > 7) {
      if (moment(date).isSame(moment(), 'years')) {
        return moment(date).format('M월 D일 hh:mm')
      }

      return moment(date).format('YYYY-MM-DD')
    }
    if (diffDay === 0) {
      if (diffTime < 60) {
        return `${diffTime}분 전`
      }
      return `${Math.floor(diffTime / 60)}시간 전`
    }
    return `${diffDay}일 전`
  }

  const notificationType = notification => {
    switch (notification?.type) {
      case 'NEW_ALBUM':
        return '새 음악 발매'
      case 'MELON_LISTENER_NEW_TRACK':
      case 'MELON_LISTENER_INCREASE_TRACK':
        return notification?.title
      case 'MELON_TOP_100_TRACK':
        return '멜론 음악 상승'
      case 'ARTIST_INPLAM_OPEN':
        return '팬스팟'
      default:
        return notification?.title
    }
  }

  return (
    <Flex
      style={
        {
          padding: '10px 20px',
          // borderBottom: `0.5px solid ${colors.light_pink}`,
          cursor: 'pointer',
          flex: 1,
          opacity: item?.isRead ? 0.5 : 1,
        }
      }
      onClick={
        () => {
          onClickNotificationItem(item)
        }
      }
      type={'column'}
    >
      <Flex justify={'space-between'} style={{ marginBottom: '4px' }}>
        <Caption1 align={'left'} type={'Regular'} color={'#646464'}>
          {notificationType(item)}
        </Caption1>
        <Flex align="center">
          <Caption1 color={colors.brown_grey} align={'left'}>
            {dateFromString(item?.createdAt)}
          </Caption1>
        </Flex>
      </Flex>
      <Flex>
        <Flex
          style={
            {
              gap: '4px',
              flex: 1,
              userSelect: 'none',
            }
          }
          // justify={'center'}
          type={'column'}
        >
          <H6 type={'Medium'} align={'left'}>
            {item.body}
          </H6>
        </Flex>
        {
          (!!item?.trackInfo?.albumInfo?.image64Path
          || !!item?.albumInfo?.image64Path) && (
            <img
              src={
                STORAGE_URL
              + (item?.trackInfo?.albumInfo?.image64Path
                || item?.albumInfo?.image64Path)
              }
              alt="notification_img"
              style={
                {
                  width: '48px',
                  height: '48px',
                  borderRadius: '4px',
                  marginLeft: '8px',
                }
              }
            />
          )
        }
      </Flex>
    </Flex>
  )
}

export default NotificationItem
