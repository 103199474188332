import React, { useEffect, useState } from 'react'
import {
  BodyWrapper,
  Caption1,
  Caption2,
  Flex,
  H3,
  H5,
  H6,
} from '@components/atoms'
import { plam_mbti_loading_gif } from '@images/'
import styled, { keyframes } from 'styled-components'
import { MbtiBtn } from '@components/molecules'
import { useHistory } from 'react-router-dom'
import {
  app_icon,
  apple_logo,
  download_qr,
  fluent_arrow_24,
  playstore_logo,
} from '@images/index'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { BurgerMenu, ConfirmPopup } from '@components/organisms'
import QRCode from 'react-qr-code'
import { colors } from '@colors/'
import NotificationItem from '@components/molecules/box/NotificationItem'
import { useStore } from '@utils/hooks'
import ReactLoading from 'react-loading'
import { Observer } from 'mobx-react'
import { ANDROID_STORE_URL, IOS_STORE_URL, STORAGE_URL } from '../../resources'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const NotificationTemplate = ({
  notificationList,
  fetchNotifications,
  history,
}: {
  notificationList: {
    _id: string,
    body: string,
    createdAt: string,
    deletedAt: null,
    rewardId: string,
    title: string,
    type: string,
    updatedAt: string,
    userId: string,
  }[],
  fetchNotifications: () => any,
}) => {
  const { authStore, loadingStore } = useStore()

  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [isFetching, setIsFetching] = useState(false)
  const [isFinish, setIsFinish] = useState(false)

  const fetchMoreNotification = async () => {
    const result = await fetchNotifications({
      __offset: authStore.notificationOffset + 1,
      __limit: 20,
      type:
        authStore?.currentUser?.type === 'artist'
        || authStore?.currentUser?.type === 'company'
          ? 'NEW_ALBUM,ARTIST_INPLAM_OPEN,MELON_LISTENER_NEW_TRACK,MELON_LISTENER_INCREASE_TRACK,MELON_TOP_100_TRACK'
          : 'NEW_ALBUM,MELON_TOP_100_TRACK',
    })

    setIsFetching(false)

    if (!result) {
      setIsFinish(true)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY // 현재 스크롤 위치
      const windowHeight = window.innerHeight // 뷰포트 높이
      const fullHeight = document.documentElement.scrollHeight // 전체 문서 높이

      if (scrollTop + windowHeight >= fullHeight * 0.9 && !isFetching) {
        setIsFetching(true) // 중복 호출 방지를 위해 상태 업데이트
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [isFetching])

  useEffect(() => {
    if (isFetching && !isFinish) {
      fetchMoreNotification()
    }
  }, [isFetching])

  const onClickNotificationItem = async notification => {
    switch (notification?.type) {
      case 'NEW_ALBUM':
        window.location.href = `/album/${notification?.albumId}`
        break
      case 'MELON_LISTENER_NEW_TRACK':
        window.location.href = `/track/${notification?.trackId}`
        break
      case 'MELON_LISTENER_INCREASE_TRACK':
        window.location.href = `/track/${notification?.trackId}`
        break
      case 'MELON_TOP_100_TRACK':
        window.location.href = `/track/${notification?.trackId}`
        break
      case 'ARTIST_INPLAM_OPEN':
        window.location.href = `/fanspot/${notification?.artistInfo?.uniqueName}`
        break
      default:
    }
  }

  return (
    <div style={{ position: 'relative', overflow: 'auto', height: '100vh' }}>
      <Header
        className={'header'}
        style={
          {
            backgroundColor: `rgba(255, 255, 255, 1)`,
          }
        }
        justify="space-between"
        align="center"
      >
        <Flex
          style={{ width: '44px', height: '44px', cursor: 'pointer' }}
          align="center"
          justify="center"
          onClick={
            () => {
              history.goBack()
            }
          }
        >
          <PrevButton src={fluent_arrow_24} />
        </Flex>
        <HeaderTitle
          style={{ flex: 1, paddingRight: '44px' }}
          align={'center'}
          justify={'center'}
        >
          <H5 type={'Bold'}>{'알림'}</H5>
        </HeaderTitle>
      </Header>
      <BodyWrapper
        id={'notification_template'}
        style={
          {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 64,
            minHeight: '100vh',
          }
        }
      >
        <Flex
          type="column"
          style={
            {
              height: '100%',
            // marginTop: '56px',
            }
          }
        >
          <Observer>
            {
              () => {
                if (
                  (!notificationList || notificationList?.length === 0)
                && !loadingStore.isLoading
                ) {
                  return (
                    <Flex justify={'center'} style={{ marginTop: '32px' }}>
                      <H5 color={colors.brownish_grey}>알림이 없습니다</H5>
                    </Flex>
                  )
                }

                return notificationList?.map((val, index) => {
                  return (
                    <NotificationItem
                      key={val._id}
                      item={val}
                      index={index}
                      onClickNotificationItem={onClickNotificationItem}
                    />
                  )
                })
              }
            }
          </Observer>
          {
            isFetching && !isFinish && (
              <ReactLoading
                type="spin"
                color="black"
                style={
                  {
                    width: '40px',
                    height: '40px',
                    margin: '0 auto',
                    marginTop: '16px',
                  }
                }
              />
            )
          }
        </Flex>
      </BodyWrapper>
    </div>
  )
}

export default NotificationTemplate

const Header = styled(Flex)`
  height: 52px;
  padding-right: 8px;
  position: sticky;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  transition: background-color 0.3s ease; /* 배경색 부드럽게 전환 */
`

const HeaderTitle = styled(Flex)`
  color: #000;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(0);
`

const PrevButton = styled.img`
  width: 32px;
  height: 32px;
`
