import { Caption1, Flex, H4, H6, Input } from '@components/atoms'
import { InplamArtistListItem } from '@components/molecules'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { move_btn_bottom_img, move_down_gray_img } from '@images/'
import { colors } from '@colors'
import InplamArtistScoreListItem from '@components/molecules/box/InplamArtistScoreListItem'

const TotalInplamArtistScoreList = ({
  list,
  setOffset,
  more,
  fetchTotalList,
  offset,
  isFavorite,
  isHome = true,
  onChange = () => {},
  inputValue = '',
  keyword = '',
}) => {
  const history = useHistory()

  const [isFirst, setIsFirst] = useState(true)

  const data = isFirst ? list?.slice(0, 10) : list

  return (
    <Flex
      type="column"
      style={
        {
          background: '#ffffff',
          padding: isHome ? '0px 20px' : 0,
          paddingBottom: '64px',
        }
      }
    >
      <Flex
        justify="space-between"
        align="center"
        style={{ marginBottom: '8px', marginTop: '32px' }}
      >
        <H4 type="Bold">팬스팟 리스트</H4>
      </Flex>
      {
        isHome && (
          <SearchInplamInput
            placeholder="팬스팟 검색"
            onChange={onChange}
            value={inputValue}
            keyword={keyword}
          />
        )
      }
      <List>
        <Container style={{ height: '48px' }} align="center">
          <ArtistName align="center">
            <Caption1 color="#646464">아티스트</Caption1>
          </ArtistName>

          <Score align="center" justify="center">
            <Caption1 align="center" color="#646464">
              팬스팟 점수
            </Caption1>
          </Score>
          <MyScore align="center" justify="center">
            <Caption1 align="center" color="#646464">
              나의 점수
            </Caption1>
          </MyScore>
        </Container>
        {
data?.length === 0 && keyword?.length > 0 ? (
  <H6 color={'#646464'} style={{ marginTop: '16px' }}>
            검색 결과가 없습니다
  </H6>
) : (
          data?.map(item => (
            <InplamArtistScoreListItem
              item={item}
              key={item._id}
              onClick={
                () => {
                  if (item?.isInplamOpen) {
                    history.push(
                      `/fanspot/${item.uniqueName}?offset=0&sortBy=createdAt`,
                    )
                  }
                  else {
                    history.push(`/artist/${item.uniqueName}`)
                  }
                }
              }
            />
          ))
)
        }
      </List>
      {
        (more || (isFirst && list?.length > 10)) && (
          <Flex
            justify="center"
            align="center"
            style={
              {
                width: '100%',
                margin: '8px 0 0 0',
                height: 40,
                cursor: 'pointer',
                border: 'solid 1px #d4d4d4',
                borderRadius: '4px',
              }
            }
            onClick={
              async () => {
                if (isFirst) {
                  setIsFirst(false)
                }
                else {
                  const options = {
                    __offset: offset,
                    __limit: 20,
                    __isFavorite: isFavorite,
                  }

                  if (keyword) {
                    options.__name = keyword
                  }
                  await fetchTotalList(options)
                  setOffset(prevState => prevState + 1)
                }
              }
            }
          >
            <H6>아티스트 더보기</H6>
            {/* <img
              src={move_btn_bottom_img}
              style={{ marginLeft: 4, width: 16, height: 16 }}
              alt="more_img"
            /> */}
          </Flex>
        )
      }
    </Flex>
  )
}

export default TotalInplamArtistScoreList

const List = styled(Flex)`
  position: relative;
  flex-direction: column;
`

const Select = styled.select`
  font-size: 12px;
  font-family: NotoSansCJKkr-Medium;
  -webkit-appearance: none;
  border: none;
  color: ${colors.brown_grey};

  background-color: transparent;
  background-image: url(${move_down_gray_img});
  background-position: right;
  background-size: 12px;
  background-repeat: no-repeat;
  display: block;

  width: 50px;
  background-position-x: 100%;

  /* option:not(:first-of-type) {
    font-size: 1.5em;
  } */
`
const Container = styled(Flex)`
  height: 48px;
  background-color: #fff;
  border-bottom: solid 1px #f4f4f4;
  margin-left: -20px;
  width: calc(100% + 40px);
  max-width: 440px;
  flex: 1;
`

const ArtistName = styled(Flex)`
  width: 176px;
  height: 48px;
  padding-left: 22px;
  flex: 1;
`
const Score = styled(Flex)`
  width: 92px;
  height: 48px;
  /* flex: 0.25; */
`
const MyScore = styled(Flex)`
  width: 92px;
  height: 48px;
  /* flex: 0.25; */
`

const SearchInplamInput = styled(Input)`
  width: 100%;
  height: 40px;
  padding: 11.5px 12px;

  border: none !important;
  background-color: #f4f4f4;
  font-size: 12px;

  box-sizing: border-box;

  margin-top: 8px;

  /* ${({ keyword }) =>
    keyword !== ''
    && css`
      width: 80%;
    `} */
`
