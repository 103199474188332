import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'

import {
  Flex,
  BodyWrapper,
  H5,
  Caption1,
  Caption2,
  H6,
} from '@components/atoms'
import { LoginPopup, BurgerMenu, UploadPhotoInfo } from '@components/organisms'
import { colors } from '@colors/'
import { Helmet } from 'react-helmet'
import qs from 'qs'
import {
  check_empty_img,
  check_img,
  default_profile_img,
  star_fill_24,
  drop,
  fluent_arrow_24,
} from '@images/'
import { convertToPricingComma } from '@utils/format'
import { Image } from '@components/molecules'
import { STORAGE_URL } from '@consts'

const InPlamAddTemplate = ({
  currentUser,
  fileList,
  setFileList,
  title,
  setTitle,
  content,
  setContent,
  usingPointValue,
  setUsingPointValue,
  _createArticle,
  toggleCancel,
  artistInfo,
  isUpdate,
  isChange,
  showLoginPopup,
  setShowLoginPopup,
  checkAuth,
  isUploading,
  setIsUploading,
  isAnonymous,
  handleAnonymous,
  setIsFanSpotModalOpen,
  setIsCategoryModalOpen,
  isFavorite,
  selectedCategory,
}) => {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const handleFanSpotBottomModalOpen = () => {
    setIsFanSpotModalOpen(true)
  }

  const handleCategoryBottomModalOpen = () => {
    setIsCategoryModalOpen(true)
  }

  return (
    <>
      <Helmet>
        <title>{`(${artistInfo && artistInfo.name}) 팬스팟`}</title>
        <meta
          name="description"
          content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
        />
      </Helmet>
      {/* <BurgerMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        pageWrapId={'comment_template'}
        outerContainerId={'App'}
        pageTitle={artistInfo && artistInfo.name}
        pageId={artistInfo && artistInfo.uniqueName}
        pageType="artist"
      /> */}
      <Flex
        style={{ width: '100%', height: '52px', background: '#fff' }}
        justify="space-between"
        align="center"
      >
        {/* <CancelBtn onClick={toggleCancel}>취소</CancelBtn> */}
        <Flex
          style={{ width: '44px', height: '44px' }}
          align="center"
          justify="center"
          onClick={toggleCancel}
        >
          <PrevButton src={fluent_arrow_24} />
        </Flex>
        <H5>글쓰기</H5>
        <SubmitBtn
          disable={
            title === ''
            || content === ''
            || isUploading
            || (!isUpdate && usingPointValue < 10)
          }
          onClick={_createArticle}
          isUpdate={isUpdate}
          isChange={isChange}
        >
          {isUpdate ? '수정' : '등록'}
        </SubmitBtn>
      </Flex>
      <BodyWrapper id="comment_template" style={{ paddingTop: 0 }}>
        <AddFormBox>
          <Flex
            onClick={
              () => {
                if (query?.isHome) {
                  handleFanSpotBottomModalOpen(true)
                }
              }
            }
            align={'center'}
            style={{ marginBottom: '12px' }}
          >
            <Image
              src={
                artistInfo?.image64Path
                  ? STORAGE_URL + artistInfo?.image64Path
                  : default_profile_img
              }
              size={['32px', '32px']}
              style={
                {
                  marginRight: '12px',
                  borderRadius: '32px',
                // border: `solid 0.5px ${colors.inActive}`,
                }
              }
              onError={e => {}}
            />
            <H6>{artistInfo?.name || ''}</H6>
            {
              isFavorite && (
                <Image
                  src={star_fill_24}
                  size={['20px', '20px']}
                  style={{ marginLeft: '2px' }}
                />
              )
            }

            {
              !!query?.isHome && (
                <Image
                  src={drop}
                  size={['12px', '12px']}
                  style={{ marginLeft: '4px', marginTop: '2px' }}
                />
              )
            }
          </Flex>

          <Br />
          <Flex type="column" onClick={e => checkAuth(e)}>
            <Flex align={'center'} style={{ gap: '8px' }}>
              <Flex
                onClick={
                  () => {
                    handleCategoryBottomModalOpen(true)
                  }
                }
                align={'center'}
                style={{ cursor: 'pointer' }}
              >
                <H6>{selectedCategory?.name || '자유'}</H6>
                <Image
                  src={drop}
                  size={['12px', '12px']}
                  style={{ marginLeft: '4px', marginTop: '2px' }}
                />
              </Flex>
              <TitleInput
                placeholder="제목"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </Flex>
            <Br />
            <ContentInput
              placeholder="내용"
              minRows={8}
              value={content}
              onChange={e => setContent(e.target.value)}
            />
            <Br style={{ position: 'relative' }}>
              {
                !isUpdate && (
                  <Flex
                    style={
                      {
                        position: 'absolute',
                        right: '20px',
                        top: '15px',
                        curosor: 'pointer',
                      }
                    }
                    align="center"
                    onClick={() => handleAnonymous(!isAnonymous)}
                  >
                    <img
                      src={isAnonymous ? check_img : check_empty_img}
                      alt="check_box"
                      style={{ width: 16, height: 16, marginRight: 2 }}
                    />
                    <Caption1>익명</Caption1>
                  </Flex>
                )
              }
            </Br>
            <UploadPhotoInfo
              imageList={fileList}
              setImageList={setFileList}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />

            {
              !isUpdate && (
                <>
                  <Flex type="column" style={{ gap: '8px' }}>
                    <Flex align="center" justify="space-between">
                      <Caption1>기여 포인트</Caption1>
                      <Caption2
                        type="Regular"
                        style={{ whiteSpace: 'pre-wrap' }}
                        color="#646464"
                      >
                        {
                          `보유 포인트  ${convertToPricingComma(
                        currentUser?.currentPoint,
                          )}`
                        }
                      </Caption2>
                    </Flex>
                    <Flex style={{ whiteSpace: 'pre-wrap' }}>
                      <PointInput
                        placeholder="10"
                        type="number"
                        style={
                          {
                            position: 'absolute',
                            opacity: 0,
                            // pointerEvents: 'none',
                          }
                        }
                        value={usingPointValue}
                        onChange={e => setUsingPointValue(Number(e.target.value))}
                        onBlur={
                          () => {
                            if (currentUser?.currentPoint <= usingPointValue) {
                              setUsingPointValue(
                                Number(currentUser?.currentPoint || 0),
                              )
                            }
                          }
                        }
                        maxLength={9}
                      />
                      <H6 align="left">
                        {`${convertToPricingComma(usingPointValue)}`}
                      </H6>
                      <H6 align="left">{` 포인트`}</H6>
                    </Flex>
                    {
                      usingPointValue < 10 && (
                        <Caption2 type="Regular" align="left" color="#ea4653">
                      10포인트 이상 입력해주세요.
                        </Caption2>
                      )
                    }
                  </Flex>

                  <FitBr style={{ margin: '16px 0' }} />

                  <PointList>
                    {
[10, 100, 1000, 10000, 100000]?.map(point => (
  <PointItem
    type="Regular"
    onClick={
      () => {
        setUsingPointValue(val => {
          if (currentUser?.currentPoint <= point) {
            return Number(currentUser?.currentPoint || 0)
          }
          return Number(point)
        })
      }
    }
  >
    {`${convertToPricingComma(point)}P`}
  </PointItem>
))
                    }
                  </PointList>
                </>
              )
            }
          </Flex>
        </AddFormBox>
        <Flex
          type={'column'}
          style={
            {
              marginTop: '24px',
              paddingTop: '24px',
              paddingBottom: '24px',
              gap: '4px',
              background: '#FAFAFA',
              flex: 1,
            }
          }
        >
          <NoticeText type={'Regular'}>
            • 새로운 글을 작성에는 팬스팟 커뮤니티에 10포인트 이상의 기여가
            필요하며, 적립된 포인트는 아티스트 및 개인 팬스팟 스코어에
            적용됩니다.
          </NoticeText>
          <NoticeText type={'Regular'}>
            • 팬스팟 게시글에서 다른 팬스팟 유저의 좋아요 및 댓글을 1포인트가
            글쓴이 및 팬스팟 스코어에 적립되며 한 게시물에서 유저 당 1회에 한
            합니다.
          </NoticeText>
          <NoticeText type={'Regular'}>
            • 포인트는 일일 출석체크 등 앱 내 포인트 적립 이벤트를 통하여 추가로
            적립 받으실 수 있습니다.
          </NoticeText>
          <NoticeText type={'Regular'}>
            • 팬스팟 커뮤니티에서 다수의 신고 및 불법 행위가 발견되어 게시글이
            삭제될 시 해당 게시물로 적립된 팬스팟 포인트는 소멸될 수 있습니다.
          </NoticeText>
        </Flex>
        {
          showLoginPopup && (
            <LoginPopup handleClose={() => setShowLoginPopup(false)} />
          )
        }
      </BodyWrapper>
    </>
  )
}

export default InPlamAddTemplate

const NoticeText = styled(Caption2)`
  color: #646464;
  text-align: left;
`

const PointList = styled(Flex)`
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 8px;
`

const PointItem = styled(Caption1)`
  height: 28px;
  padding: 5px 15px;
  border-radius: 14px;
  border: solid 1px #d4d4d4;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
`

const AddFormBox = styled(Flex)`
  /* margin-top: 56px; */
  flex-direction: column;
`

const CancelBtn = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  box-sizing: border-box;
  background-color: ${colors.whitef4};
  font-size: 12px;
  color: ${colors.black};
`
const SubmitBtn = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  box-sizing: border-box;

  cursor: pointer;

  font-size: 14px;
  color: ${colors.light_pink};

  ${props =>
    !props.disable
    && css`
      color: ${colors.black};
    `}


  ${props =>
    props.isUpdate
    && css`
      color: ${colors.light_pink};
    `}

  ${props =>
    props.isUpdate
    && props.isChange
    && css`
      color: ${colors.black};
    `}
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: solid 1px ${colors.whitef4};
`
const FitBr = styled(Flex)`
  width: calc(100%);
  border-bottom: solid 1px ${colors.whitef4};
`

const TitleInput = styled.input`
  border: none;
  width: 100%;
  height: 24px;
  font-size: 15px;
  font-family: NotoSansCJKkr-Regular;
  margin: 16px 0;
`

const ContentInput = styled(TextareaAutosize)`
  margin: 16px 0px;
  border: none;
  resize: none;
  font-size: 14px;
  font-family: NotoSansCJKkr-Regular;
`

const PointInput = styled.input`
  width: fit-content;
  border: none;
  height: 20px;
  font-size: 14px;
  font-family: NotoSansCJKkr-Medium;
  word-break: keep-all;
  font-weight: 500;
  margin: 0;
  padding: 0;
`

const PrevButton = styled.img`
  width: 32px;
  height: 32px;
`
