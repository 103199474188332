/*
 * 검색 아티스트 상세 결과
 * made by : LeeHaehun
 * updatedAt : 2020. 08. 25
 */

import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { Flex, H6, Caption1, H5 } from '@components/atoms'
import {
  default_profile_img,
  default_music_img,
  move_btn_img,
  move_prev_btn_img,
} from '@images'
import { STORAGE_URL } from '@consts/'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import {
  SearchArticleItem,
  SearchArtistItem,
  SearchFanSpotItem,
} from '@components/molecules'

const SearchFanSpotList = ({
  showDetail,
  setIsSearch,
  searchArtistList,
  searchFanSpotList,
  searchArticleList,
  keyword,
  firstFetchSearchDetail,
  moreFetchSearchDetail,
}) => {
  const [fanSpotOffset, setFanSpotOffset] = useState(0)
  const [isHasFanSpotMore, setIsHasFanSpotMore] = useState(true)

  const [offset, setOffset] = useState(0)
  const [isHasMore, setIsHasMore] = useState(true)

  const [isFetching, setIsFetching] = useState(false)

  // 스크롤 시,
  // offset 카운트
  const countOffset = () => {
    setOffset(val => val + 1)
  }

  const countFanSpotOffset = () => {
    setFanSpotOffset(val => val + 1)
  }

  const onLoadMore = async _offset => {
    if (_offset < 1) {
      const res = await firstFetchSearchDetail('article', keyword)
      setIsHasMore(res)
    }
    else {
      const res = await moreFetchSearchDetail('article', keyword, _offset)
      setIsHasMore(res)
    }
  }

  const onLoadMoreFanSpot = async _offset => {
    setIsFetching(true)
    if (_offset < 1) {
      const res = await firstFetchSearchDetail('fanspot', keyword)
      setIsHasFanSpotMore(res)
    }
    else {
      const res = await moreFetchSearchDetail('fanspot', keyword, _offset)
      setIsHasFanSpotMore(res)
    }
    setIsFetching(false)
  }

  // offset 변경 시, 다음 검색 결과 값 fetch
  useEffect(() => {
    onLoadMore(offset)
  }, [offset])

  // offset 변경 시, 다음 검색 결과 값 fetch
  useEffect(() => {
    onLoadMoreFanSpot(fanSpotOffset)
  }, [fanSpotOffset])

  const scrollContainerRef = useRef(null)

  const handleScroll = useCallback(() => {
    const container = scrollContainerRef.current

    if (!container) return

    const { scrollLeft, scrollWidth, clientWidth } = container

    // 스크롤이 끝에 가까워지면 next 함수 호출
    if (
      scrollLeft + clientWidth >= scrollWidth * 0.9
      && isHasFanSpotMore
      && !isFetching
    ) {
      countFanSpotOffset()
    }
  }, [countFanSpotOffset, isHasFanSpotMore, setIsFetching])

  return (
    <Flex type="column">
      <Flex
        style={{ alignItems: 'center' }}
        style={
          {
            alignItems: 'center',
            padding: '0 20px',
            height: '36px',
          }
        }
      >
        {/* <img
          src={move_prev_btn_img}
          style={{ widht: '20px', height: '20px' }}
        /> */}
        <H5 type="Bold" align="left">
          팬스팟
        </H5>
      </Flex>
      {
searchFanSpotList?.length === 0 && searchArticleList?.length === 0 && (
          <Flex style={{ padding: '0 20px' }}>
            <Caption1 align="left">검색 결과가 없습니다.</Caption1>
          </Flex>
        )
      }
      {
        searchFanSpotList && searchFanSpotList.length >= 1 && (
          <RowList
            ref={scrollContainerRef}
            onScroll={handleScroll}
            style={
              {
                display: 'flex',
                overflowX: 'scroll', // 가로 스크롤 활성화
                padding: '0 20px',
                gap: '12px', // 아이템 간 간격 조정
                marginTop: '10px',
              }
            }
            className="scroll2_div"
          >
            {
              searchFanSpotList.map((item, index) => (
                <SearchFanSpotItem
                  item={item}
                  key={item?._id || index}
                  setIsSearch={setIsSearch}
                />
              ))
            }
            {
              isHasFanSpotMore && isFetching && (
                <div
                  style={
                    {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100px',
                    }
                  }
                >
                  <ReactLoading
                    type="spin"
                    color="black"
                    style={{ width: '40px', height: '40px' }}
                  />
                </div>
              )
            }
          </RowList>
        )
      }
      {
        searchArticleList && searchArticleList.length >= 1 && (
        /*
         * 무한 스크롤
         * scrollThreshold : scroll 0.9(90%) 일 때, loading
         * next : loading func
         * hasMore : data의 다음 값이 있는지 boolean
         * loader : loading 컴포넌트
         */
          <InfiniteScroll
            dataLength={searchArticleList.length}
            scrollThreshold={0.9}
            next={countOffset}
            hasMore={isHasMore}
            loader={
              <ReactLoading
                type="spin"
                color="black"
                style={{ width: '40px', height: '40px', margin: '0 auto' }}
              />
            }
            style={{ overflow: 'unset', padding: '0 20px' }}
            className="scroll_div"
          >
            {
              searchArticleList.map((item, index) => {
                return (
                  <SearchArticleItem
                    item={item}
                    key={item?._id}
                    setIsSearch={setIsSearch}
                  />
                )
              })
            }
          </InfiniteScroll>
        )
      }
    </Flex>
  )
}

export default SearchFanSpotList

const RowList = styled(Flex)`
  flex-direction: row;

  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
