import React, { useEffect, useRef, useState } from 'react'
import { useLastLocation } from 'react-router-last-location'
import { inject, observer } from 'mobx-react'
import qs from 'qs'

import { HomeTemplate } from '@components/templates'
import { useStore } from '@utils/hooks'

import { IS_DEV, DOMAIN_URL_LENGTH } from '@consts'
import Axios from 'axios'

interface HomePageProps {
  recordStore: RecordStore;
  authStore: AuthStore;
  statsStore: StatsStore;
  rewardStore: RewardStore;
  articleStore: ArticleStore;
  inplamStore: InplamStore;
  match: Any;
  history: Any;
  location: Any;
}

const HomePage = ({
  match,
  history,
  location,
  recordStore,
  authStore,
  statsStore,
  rewardStore,
  articleStore,
  dataMapStore,
  inplamStore,
}: HomePageProps) => {
  const lastLocation = useLastLocation()

  /* store */
  const { useInitialLoading } = useStore()

  const enterRecord = recordStore.enterRecord || (() => {})
  const recorded = recordStore.recorded || false

  const { currentUser, likedAristList } = authStore

  const fetchUserLikeArtistList =
    authStore.fetchUserLikeArtistList || (() => {})

  const fetchUserLikeTrackList = authStore.fetchUserLikeTrackList || (() => {})

  const fetchPopularTrackList = authStore.fetchPopularTrackList || (() => {})

  const fetchBannerList = authStore.fetchBannerList || (() => {})
  const bannerList = authStore.bannerList || []

  const fetchNoticePopupList = authStore.fetchNoticePopupList || (() => {})
  const noticePopupList = authStore.noticePopupList || (() => {})

  const plamChartList = statsStore.plamChartList || null
  const latestAlbumList = statsStore.latestAlbumList || null
  const rewardList = rewardStore.rewardList || null

  const fetchPlamChart = statsStore.fetchPlamChart || (() => {})
  const fetchLatestAlbum = statsStore.fetchLatestAlbum || (() => {})
  const fetchRewardList = rewardStore.fetchRewardList || (() => {})

  const fetchPopularArticleList =
    articleStore.fetchPopularArticleList || (() => {})
  const popularArticleList = articleStore.popularArticleList || null
  const fetchArticleCategoryList = articleStore.fetchArticleCategoryList || null

  const totalInplamList = inplamStore.totalInplamList || null
  const fetchTotalInplamList = inplamStore.fetchTotalInplamList || (() => {})
  const fetchArticleList = articleStore.fetchArticleList || (() => {})

  const [isMounted, setIsMounted] = useState(false)

  const [likedTrackList, setLikedTrackList] = useState([])
  const [popularTrackList, setPopularTrackList] = useState([])

  const [likedTrackSortBy, setLikedTrackSortBy] = useState('listener')
  const [popularTrackSortBy, setPopularTrackSortBy] = useState(
    'listenerIncrease',
  )
  const [myFanSpotList, setMyFanSpotList] = useState([])
  const [notice, setNotice] = useState(null)

  const [isFavorite, setIsFavorite] = useState(1)
  const [fanSpotList, setFanSpotList] = useState([])
  const [totalInplamoffset, setTotalInplamOffset] = useState(0)
  const [more, setMore] = useState(true) // 더보기 유무
  const [keyword, setKeyword] = useState('') // 검색어

  const fetchLatestNotice = async () => {
    const options = {
      limit: 1,
      sortBy: 'createdAt',
      offset: 0,
      isNotice: true,
    }

    const result = await fetchArticleList(options)

    setNotice(result)
  }

  useEffect(() => {
    const fetchDatas = async () => {
      await Promise.all([
        fetchNoticePopupList({}),
        useInitialLoading(fetchBannerList()),
        useInitialLoading(
          fetchRewardList({
            __offset: 0,
            __limit: 20,
            isExpired: false,
          }),
        ),
        useInitialLoading(
          fetchLatestAlbum({
            limit: 30,
          }),
        ),
        useInitialLoading(fetchPopularArticleList()),
        useInitialLoading(fetchLatestNotice()),
        // useInitialLoading(
        //   fetchTotalInplamList({
        //     sortBy: 'newest',
        //     offset: 0,
        //     limit: 20,
        //     sort: -1,
        //   }),
        // ),

        useInitialLoading(fetchLikedTrackList()),
        useInitialLoading(fetchPopularList()),
      ])

      setIsMounted(true)
    }

    fetchDatas()

    if (!currentUser) {
      localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [])

  const offset = useRef(0)
  const popularOffset = useRef(0)

  useEffect(() => {
    if (currentUser) {
      useInitialLoading(
        fetchUserLikeArtistList({ loginUserId: currentUser?._id }),
      )
    }
  }, [currentUser, fetchUserLikeArtistList])

  useEffect(() => {
    if (isMounted) {
      offset.current = 0
      // setLikedTrackList([])
      fetchLikedTrackList(true)
    }
  }, [currentUser, fetchUserLikeTrackList, likedTrackSortBy])

  useEffect(() => {
    if (isMounted) {
      popularOffset.current = 0
      // setPopularTrackList([])
      fetchPopularList(true)
    }
  }, [currentUser, fetchPopularTrackList, popularTrackSortBy])

  const fetchLikedTrackList = async (isReset = false) => {
    if (currentUser) {
      const result = await fetchUserLikeTrackList({
        loginUserId: currentUser?._id,
        sortBy: likedTrackSortBy,
      })
      if (result?.data?.trackList) {
        if (isReset) {
          setLikedTrackList(val => [...result?.data?.trackList])
        }
        else {
          setLikedTrackList(val => [...val, ...result?.data?.trackList])
        }
        offset.current += 1
      }
    }
  }

  const fetchPopularList = async isReset => {
    if (currentUser?.type === 'company') {
      const result = await fetchPopularTrackList({
        offset: popularOffset.current,
        sortBy: popularTrackSortBy,
      })
      if (result?.data?.trackList) {
        if (isReset) {
          setPopularTrackList(val => [...result?.data?.trackList])
        }
        else {
          setPopularTrackList(val => [...val, ...result?.data?.trackList])
        }
        popularOffset.current += 1
      }
    }
  }

  const onPressMoreTrackList = async () => {
    const result = await fetchUserLikeTrackList({
      loginUserId: currentUser?._id,
      offset: offset.current,
      sortBy: likedTrackSortBy,
    })
    if (result?.data?.trackList) {
      setLikedTrackList(val => [...val, ...result?.data?.trackList])
      offset.current += 1
    }
  }

  const onPressMorePopularTrackList = async () => {
    if (popularOffset.current === 1 && popularTrackList?.length < 100) {
      const result = await fetchPopularTrackList({
        offset: 0,
        limit: 100,
        sortBy: popularTrackSortBy,
      })
      if (result?.data?.trackList) {
        setPopularTrackList([...result?.data?.trackList])
      }
    }
    else {
      const result = await fetchPopularTrackList({
        offset: popularOffset.current,
        limit: 100,
        sortBy: popularTrackSortBy,
      })
      if (result?.data?.trackList) {
        setPopularTrackList(val => [...val, ...result?.data?.trackList])
        popularOffset.current += 1
      }
    }
  }

  const handleChangeLikedTrackSortBy = value => {
    setLikedTrackSortBy(value)
  }

  const handleChangePopularTrackSortBy = value => {
    setPopularTrackSortBy(value)
  }

  const fetchTotalList = async ({
    __limit = 20,
    __isFavorite = 1,
    __offset = 0,
    __name,
  }) => {
    const options = {
      offset: __offset,
      limit: __limit,
      isFavorite: __isFavorite,
      noNeedStore: true,
    }

    if (__name) {
      options.name = __name
    }

    const result = await fetchTotalInplamList(options)

    if (__isFavorite === 0 || __offset > 0) {
      setFanSpotList(val => [...val, ...result])
    }
    else {
      setFanSpotList(result)
    }

    if (__isFavorite === 1 && result?.length < 20) {
      setIsFavorite(0)

      const options2 = {
        offset: 0,
        limit: 20,
        isFavorite: 0,
        noNeedStore: true,
      }

      if (__name) {
        options2.name = __name
      }

      const result2 = await fetchTotalInplamList(options2)

      setFanSpotList(val => [...val, ...result2])

      setTotalInplamOffset(1)

      if (result?.length + result2?.length >= 20) {
        setMore(true)
      }
      else {
        setMore(false)
      }
    }
    else if (result?.length >= 20) {
      setMore(true)
    }
    else {
      setMore(false)
    }
  }

  useEffect(() => {
    const source = Axios.CancelToken.source()

    const fetchMyFanSpot = async () => {
      const defaultResult = await fetchTotalInplamList({
        offset: 0,
        isDefaultFanSpot: 1,
        noNeedStore: true,
      })

      if (!authStore?.currentUser) {
        const result = await fetchTotalInplamList(
          {
            limit: 40,
            sortBy: 'inplamOpenRequestCount',
            sort: -1,
            isInplamOpen: 0,
            noNeedStore: true,
          },
          {
            cancelToken: source.token,
          },
        )

        setMyFanSpotList([...defaultResult, ...result])
      }
      else {
        const result = await fetchTotalInplamList(
          {
            limit: 40,
            isFavorite: 1,
            offset: 0,
            noNeedStore: true,
          },
          {
            cancelToken: source.token,
          },
        )

        if (result?.length === 0) {
          const result2 = await fetchTotalInplamList(
            {
              limit: 40,
              sortBy: 'inplamOpenRequestCount',
              sort: -1,
              isInplamOpen: 0,
              noNeedStore: true,
            },
            {
              cancelToken: source.token,
            },
          )
          setMyFanSpotList([...defaultResult, ...result2])
        }
        else {
          setMyFanSpotList([...defaultResult, ...result])
        }
      }
    }

    fetchMyFanSpot()

    return () => {
      source.cancel('Operation canceled by the user.')
    }
  }, [authStore?.currentUser])

  useEffect(() => {
    if (keyword) {
      fetchTotalList({
        __limit: 20,
        __isFavorite: 1,
        __offset: 0,
        __name: keyword,
      })
    }
    else {
      fetchTotalList({ __limit: 20, __isFavorite: 1, __offset: 0 })
    }
    setTotalInplamOffset(1)
  }, [keyword])

  return (
    <>
      <HomeTemplate
        currentUser={currentUser}
        plamChartList={plamChartList}
        latestAlbumList={latestAlbumList}
        rewardList={rewardList}
        fetchRewardList={fetchRewardList}
        fetchPlamChart={fetchPlamChart}
        fetchPopularArticleList={fetchPopularArticleList}
        popularArticleList={popularArticleList}
        totalInplamList={myFanSpotList}
        likedAristList={likedAristList}
        likedTrackList={likedTrackList}
        noticePopupList={noticePopupList}
        history={history}
        popularTrackList={popularTrackList}
        onPressMorePopularTrackList={onPressMorePopularTrackList}
        onPressMoreTrackList={onPressMoreTrackList}
        likedTrackSortBy={likedTrackSortBy}
        handleChangeLikedTrackSortBy={handleChangeLikedTrackSortBy}
        popularTrackSortBy={popularTrackSortBy}
        handleChangePopularTrackSortBy={handleChangePopularTrackSortBy}
        setIsFavorite={setIsFavorite}
        isFavorite={isFavorite}
        setTotalInplamOffset={setTotalInplamOffset}
        totalInplamoffset={totalInplamoffset}
        more={more}
        fetchTotalList={fetchTotalList}
        keyword={keyword}
        setKeyword={setKeyword}
        fanSpotList={fanSpotList}
        bannerList={bannerList}
        notice={notice}
      />
    </>
  )
}

export default inject(
  'recordStore',
  'authStore',
  'statsStore',
  'articleStore',
  'inplamStore',
  'dataMapStore',
  'rewardStore',
)(observer(HomePage))
