import React from 'react'
import { Flex, H6, Caption1, Caption2 } from '@components/atoms'
import { Link } from 'react-router-dom'
import { STORAGE_URL } from '@consts/'
import styled from 'styled-components'
import { colors } from '@colors/'
import { default_profile_img } from '@images'
import { handleArtistImgError } from '@utils/handler'
import Image from './Image'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const SearchFanSpotItem = ({ item, setIsSearch }) => {
  return (
    <Flex style={{}}>
      <Link
        to={`/fanspot/${item?.uniqueName}`}
        onClick={() => setIsSearch(false)}
      >
        <Flex
          type="column"
          align="center"
          style={
            {
              gap: 5,
              width: 64,
              position: 'relative',
              paddingBottom: '4px',
            }
          }
        >
          <Flex style={{ position: 'relative' }}>
            <Image
              src={
                item && item.image64Path
                  ? STORAGE_URL + item.image64Path
                  : default_profile_img
              }
              size={['64px', '64px']}
              style={
                {
                  borderRadius: '32px',
                  border: `solid 0.5px ${colors.inActive}`,
                  boxSizing: 'border-box',
                }
              }
              onError={e => handleArtistImgError(e)}
            />
            {
              !item?.isInplamOpen && (
                <Flex
                  style={
                    {
                      borderRadius: '100px',
                      position: 'absolute',
                      padding: '2px 4px',
                      background: '#d4d4d4',
                      bottom: 0,
                      left: 13,
                    }
                  }
                  align={'center'}
                  justify={'center'}
                >
                  <Caption2 type={'Medium'} color={'#646464'}>
                  오픈 전
                  </Caption2>
                </Flex>
              )
            }
          </Flex>

          <Name type={'Regular'}>{item?.name || item?.uniqueName}</Name>
        </Flex>
      </Link>
    </Flex>
  )
}

export default SearchFanSpotItem

const Name = styled(Caption1)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
