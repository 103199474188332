import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'

import { InPlamTotalTemplate } from '@components/templates'
import qs from 'qs'
import Axios from 'axios'

interface InPlamTotalPageProps {
  articleStore: ArticleStore;
  authStore: AuthStore;
  inplamStore: InplamStore;
  match: Any;
  history: Any;
  location: Any;
}

const InPlamTotalPage = ({
  match,
  location,
  history,
  authStore,
  articleStore,
  inplamStore,
}: InPlamTotalPageProps) => {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  /* store */
  const fetchTotalArticleList = articleStore.fetchTotalArticleList || (() => {})
  const fetchArticleList = articleStore.fetchArticleList || (() => {})
  const fetchArticleCategoryList =
    articleStore.fetchArticleCategoryList || (() => {})

  const currentUser = authStore.currentUser || null
  const artistInfo = articleStore.artistInfo || null
  const totalArticleList = articleStore.totalArticleList || null
  const totalArticleListCount = articleStore.totalArticleListCount || null

  const totalInplamList = inplamStore.totalInplamList || null
  const fetchTotalInplamList = inplamStore.fetchTotalInplamList || (() => {})
  /* store end */

  const [notice, setNotice] = useState(null)
  const [categoryList, setCategoryList] = useState([])

  const [offset, setOffset] = useState(0)
  const [sortBy, setSortBy] = useState('createdAt')
  const [activeTab, setActiveTab] = useState(
    query?.filterBy ? query.filterBy : 'popular',
  )
  const [searchWord, setSearchWord] = useState(
    query.searchWord ? query.searchWord : null,
  )

  const handlePagination = _offset => {
    setOffset(Number(_offset))
  }

  const handleChangeSortBy = _sortBy => {
    // if (!searchWord) {
    //   history.push(`/fanspot/total?sortBy=${_sortBy}&filterBy=${activeTab}`)
    // }
    // else {
    //   history.push(
    //     `/fanspot/total?sortBy=${_sortBy}&filterBy=${activeTab}&searchWord=${searchWord}`,
    //   )
    // }
  }
  const handleChangeFilterBy = _filterBy => {
    setOffset(0)
    if (!searchWord) {
      history.replace(`/fanspot/total?filterBy=${_filterBy}`)
    }
    else {
      history.replace(
        `/fanspot/total?filterBy=${_filterBy}&searchWord=${searchWord}`,
      )
    }
  }

  const handleChangeSearchWord = _searchWord => {
    if (!_searchWord) {
      history.replace(`/fanspot/total?filterBy=${activeTab}`)
    }
    else {
      history.replace(
        `/fanspot/total?searchWord=${_searchWord}&filterBy=${activeTab}`,
      )
    }
  }

  useEffect(() => {
    const fetchCategory = async () => {
      const result = await fetchArticleCategoryList()

      if (!result) return

      setCategoryList(result)
    }

    fetchCategory()
  }, [])

  useEffect(() => {
    setSearchWord(query.searchWord)
    if (query?.filterBy) setActiveTab(query.filterBy)
  }, [query.searchWord, query.filterBy])

  const fetchLatestNotice = async () => {
    const options = {
      limit: 1,
      sortBy: 'createdAt',
      offset: 0,
      isNotice: true,
    }

    const result = await fetchArticleList(options)

    setNotice(result)
  }

  const fetchData = async () => {
    const options = {
      limit: 20,
      sortBy,
      offset: 0,
      isNotice: activeTab === 'notice',
    }

    if (activeTab === 'popular') {
      options.isPopularArticle = 1
      options.sortBy = 'popularArticleUpdatedAt'
    }

    if (activeTab === 'info') {
      options.articleCategoryId = articleStore?.articleCategoryList?.find(
        val => val.name === '정보',
      )?._id
      options.sortBy = 'createdAt'
    }

    if (activeTab === 'free') {
      // 자유스팟 아이디로 패치하기
      options.uniqueName = 'defaultfanspot'
    }

    await fetchTotalArticleList(options)
  }

  const fetchMoreData = async () => {
    const options = {
      limit: 20,
      sortBy,
      offset,
      isNotice: activeTab === 'notice',
    }

    if (activeTab === 'popular') {
      options.isPopularArticle = 1
      options.sortBy = 'popularArticleUpdatedAt'
    }

    if (activeTab === 'info') {
      options.articleCategoryId = articleStore?.articleCategoryList?.find(
        val => val.name === '정보',
      )?._id
      options.sortBy = 'createdAt'
    }

    if (activeTab === 'free') {
      // 자유스팟 아이디로 패치하기
      options.uniqueName = 'defaultfanspot'
    }

    await fetchTotalArticleList(options)
  }

  useEffect(() => {
    if (articleStore?.articleCategoryList) {
      fetchData()
    }
    fetchLatestNotice()
  }, [articleStore?.articleCategoryList, activeTab])

  useEffect(() => {
    if (articleStore?.articleCategoryList && offset > 0) {
      fetchMoreData()
    }
    fetchLatestNotice()
  }, [articleStore?.articleCategoryList, offset])

  useEffect(() => {
    if (!currentUser) {
      window.localStorage.setItem('plam_current_page', location.pathname)
    }
  }, [currentUser])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InPlamTotalTemplate
      uniqueName={match.params.uniqueName}
      artistInfo={artistInfo}
      totalArticleList={totalArticleList}
      totalArticleListCount={totalArticleListCount}
      offset={offset}
      handlePagination={handlePagination}
      sortBy={sortBy}
      handleChangeSortBy={handleChangeSortBy}
      handleChangeFilterBy={handleChangeFilterBy}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      totalInplamList={totalInplamList}
      currentUser={currentUser}
      searchWord={searchWord}
      handleChangeSearchWord={handleChangeSearchWord}
      fetchMoreData={fetchMoreData}
      notice={notice}
    />
  )
}

export default inject(
  'authStore',
  'articleStore',
  'inplamStore',
)(observer(InPlamTotalPage))
