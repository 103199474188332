/*
 * 검색 아티스트 상세 결과
 * made by : LeeHaehun
 * updatedAt : 2020. 08. 25
 */

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, H6, Caption1, H5 } from '@components/atoms'
import {
  default_profile_img,
  default_music_img,
  move_btn_img,
  move_prev_btn_img,
} from '@images'
import { STORAGE_URL } from '@consts/'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import { SearchArtistItem } from '@components/molecules'

const SearchArtistList = ({
  showDetail,
  setIsSearch,
  searchArtistList,
  keyword,
  firstFetchSearchDetail,
  moreFetchSearchDetail,
}) => {
  const [offset, setOffset] = useState(0)
  const [isHasMore, setIsHasMore] = useState(true)

  // 스크롤 시,
  // offset 카운트
  const countOffset = () => {
    setOffset(offset + 1)
  }

  const onLoadMore = async () => {
    if (offset < 1) {
      const res = await firstFetchSearchDetail('artist', keyword)
      setIsHasMore(res)
    }
    else {
      const res = await moreFetchSearchDetail('artist', keyword, offset)
      setIsHasMore(res)
    }
  }

  // offset 변경 시, 다음 검색 결과 값 fetch
  useEffect(() => {
    onLoadMore()
  }, [offset])

  return (
    <Flex type="column" style={{ padding: '0px 20px' }}>
      <Flex style={{ alignItems: 'center', height: '36px' }}>
        <H5 type="Bold" align="left">
          아티스트
        </H5>
      </Flex>
      {
        searchArtistList && searchArtistList.length >= 1 ? (
        /*
         * 무한 스크롤
         * scrollThreshold : scroll 0.9(90%) 일 때, loading
         * next : loading func
         * hasMore : data의 다음 값이 있는지 boolean
         * loader : loading 컴포넌트
         */
          <InfiniteScroll
            dataLength={searchArtistList.length}
            scrollThreshold={0.9}
            next={countOffset}
            hasMore={isHasMore}
            loader={
              <ReactLoading
                type="spin"
                color="black"
                style={{ width: '40px', height: '40px', margin: '0 auto' }}
              />
            }
            style={{ overflow: 'unset', gap: 0 }}
          // className="scroll_div"
          >
            {
              searchArtistList.map((item, index) => {
                return (
                  <SearchArtistItem
                    item={item}
                    key={index}
                    setIsSearch={setIsSearch}
                  />
                )
              })
            }
          </InfiniteScroll>
        ) : (
          <Caption1 align="left">검색 결과가 없습니다.</Caption1>
        )
      }
    </Flex>
  )
}

export default SearchArtistList
